import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { ApplicationDetail } from './ApplicationDetail';
import { ApplicationReview } from './ApplicationReview';
import { withStyles } from '@material-ui/core/styles';
import { Route, withRouter } from 'react-router-dom';
import { Navigation } from '../../Navigation';
import moment from 'moment';
import { HideFooter } from '../../HideFooter.js';
import { COPYRIGHT_BUSINESSENTITIES, ERRORMESSAGE, RECAPTCHA_ACTIONS } from '../../Constants.js';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://shop.greatworld.com.sg/">
      {new Date().getFullYear()} {COPYRIGHT_BUSINESSENTITIES}
      </Link>{' '}
      
      {'.'}

    </Typography> 
  );
} 
const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    align: 'right'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});
const steps = ['Details', 'Review your application'];

const validateForm = errors => {
  let valid = true;
  for (const [key, value] of Object.entries(errors)) {
    if(value.length > 0)
      valid = false;
  }
  return valid;
};

class SeasonParkingApplication extends Component {
  constructor(pros) {
    super(pros);
    this.state = {
      activeStep: 0, 
      building: "",
      startDate: "",
      endDate: "",
      applicationMonth: 0,
      applicationType: "Public",
      units: "",

      name: "",
      phone: "",
      email: "",
      companyName: "",
      carCount: -1,
      records: [],
      remark: "",

      reCaptchaToken: "",

      isFirstTime: 0,
      disabled: false,
      modalIsOpen:false,
      modal2IsOpen:false,
      
      errors: {
        startDate: "Field is required",
        applicationMonth:"Field is required",
        name: "Field is required",
        phone: "Field is required",
        email: "Field is required",
        companyName: "Field is required",
        plateAndIU: "Field is required",
        iu:""
      }
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    const postData = { building: this.props.location.state.building};
    this.setState({
      building: postData.building,
    });
  }
  handleNext() { 
    //Validate field before proceed to Review page
    let errors = this.state.errors; 
    if(this.state.startDate == "")
      errors.startDate = "Field is required"
    if(this.state.applicationMonth.value == 0)
      errors.applicationMonth = "Field is required"
    if(this.state.name == "")
      errors.name = "Field is required"
    if(this.state.email == "")
      errors.email = "Field is required"
    if(this.state.phone == "")
      errors.phone = "Field is required"
    if(this.state.companyName == "")
      errors.companyName = "Field is required"
      errors.plateAndIU="";
      errors.iu="";
    if( validateForm(errors) == false )
      {
       this.setState({
         errors: errors
       }); 
       return;
      }
    //Create Season Parking Application
     if (this.state.activeStep == 1) {
           //Control Next/Back buttun
      this.setState({ disabled: true })
      let startDate = moment(this.state.startDate).format('YYYY-MM-DD');
      let endDate = moment(this.state.endDate).format('YYYY-MM-DD');
      let carCount = this.state.carCount;
      let records = null;
      if(carCount == 1)
      {
        records = this.state.records.slice(0,1);
      }
      else
      {
        records = this.state.records;
      }
      //continue after getting recaptcha token
      new Promise(function(resolve,reject){
        try{
          window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: RECAPTCHA_ACTIONS.SeasonParkingApplication })
          .then(token => {
            resolve(token);
          })
        }catch(error){
          reject(error);
        }
      }).catch(() => {
        alert(ERRORMESSAGE.recaptcha);
        this.setState({                
          disabled: false
        })
      }).then((value)=>{
        this.setState({reCaptchaToken:value})
        const applicationData = {
          building : this.state.building,
          startDate :  startDate,
          endDate : endDate,
          applicationType : this.state.applicationType,
          units : this.state.units,
          
          name : this.state.name,
          phone : this.state.phone,
          email : this.state.email,
          companyName : this.state.companyName,
          remark : this.state.remark,
          recaptchaToken : this.state.reCaptchaToken,
          isSecond : 0,
          records : records
        };
        console.log(process.env.REACT_APP_API + 'CreateSeasonParking')
        fetch(process.env.REACT_APP_API + 'CreateSeasonParking', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(applicationData)
        })
        .then(data => data.json())
        .then((data) =>{
          if (data.result === "ok") {
            this.setState({
              activeStep: this.state.activeStep + 1,
              disabled: false
            });
          } else {
            //Result is fail  
            alert(data.Message)
            this.state.activeStep = 0;
            this.setState({
              disabled: false
            });
          } 
        })
      })
      }else{
        this.setState({disabled: true});
        const postData = { 
          building: this.state.building,
          plate: this.state.records[0].plate,
          iu:this.state.records[0].iu
        };
      fetch(process.env.REACT_APP_API + 'LoginIu', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
      })
        .then(data => {
          if (data.ok) {
            return data.json()
          }
          else {
            return Promise.reject(data)
          }
        })
        .then(
          (data) => {
            //Result is ok
            if (data.result === "ok"&&data.iuplatevalid==="1") {
              this.setState({
                activeStep: this.state.activeStep + 1
              });
            } else {
              //Result is fail 
              let errors = this.state.errors; 
              errors.plateAndIU= typeof data.Message === "undefined" ? data.reason : data.Message;
              errors.iu= typeof data.Message === "undefined" ? data.reason : data.Message;
              this.setState({
                errors:errors
              });
            }
            this.setState({disabled:false});
          })
          // Catch error messages from bad responses
          .catch((data) => {
            data.json().then((jsonResult) => {
              let errors = this.state.errors;
              errors.iu = jsonResult.Message;
              this.setState({
                errors:errors
              });
            })
          }) 
     } 
  }

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    });
  }

  callbackDetail = (childData) => {
    this.setState({
      startDate: childData.startDate,
      endDate: childData.endDate,
      applicationMonth: childData.applicationMonth,
      applicationType: childData.applicationType,
      units: childData.units,

      name: childData.name,
      phone: childData.phone,
      email: childData.email,
      companyName: childData.companyName,
      carCount: childData.carCount,
      records: childData.records,
      remark: childData.remark,
      isFirstTime : childData.isFirstTime,
      errors: childData.errors
    });
  }

  getStepContent(step, callbackDetail) {
    switch (step) {
      case 0:
        return <ApplicationDetail
        building = {this.state.building}
        startDate= {this.state.startDate}
        endDate= {this.state.endDate}
        applicationMonth= {this.state.applicationMonth}
        applicationType= {this.state.applicationType}
        units= {this.state.units}
  
        name= {this.state.name}
        phone= {this.state.phone}
        email= {this.state.email}
        companyName= {this.state.companyName}
        carCount= {this.state.carCount}
        records= {this.state.records}
        remark= {this.state.remark}
        isFirstTime = {this.state.isFirstTime}
        errors = {this.state.errors}
        parentCallback={callbackDetail} />;
      case 1:
        return <ApplicationReview
        building = {this.state.building}
        startDate= {this.state.startDate}
        endDate= {this.state.endDate}
        applicationMonth= {this.state.applicationMonth}
        applicationType= {this.state.applicationType}
        units= {this.state.units}
  
        name= {this.state.name}
        phone= {this.state.phone}
        email= {this.state.email}
        companyName= {this.state.companyName}
        carCount= {this.state.carCount}
        records= {this.state.records}
        remark= {this.state.remark} />;
      default:
        throw new Error('Unknown step');
    }
  }

  render() {
    const { classes } = this.props;
   
    return (
      <React.Fragment>
        <Navigation />
        <CssBaseline />
        <AppBar position="absolute" color="default" className={classes.appBar}>
          {/* <Toolbar>
            <Typography variant="h6" color="inherit" noWrap>
              Season Parking Extension
            </Typography>
          </Toolbar> */}
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Season Parking Application (for 1st Vehicle)
            </Typography>
            <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {this.state.activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your application.
                  </Typography>
                  <Typography variant="subtitle1">
                  Your season parking application has been submitted for approval.  Once it has been approved, you will receive a confirmation email with payment details. Please note that payment can only be made through a QR code which will be embedded in the confirmation email. This QR code will only be valid for 48 hours.
                  </Typography>
                  <br/>
                  <Typography variant="subtitle1">
                  For further query, please contact it.support@allgreen.com.sg. 
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.getStepContent(this.state.activeStep, this.callbackDetail)}
                  <div className={classes.buttons}>
                  
                    {this.state.activeStep !== 0 && (
                      <Button onClick={this.handleBack} className={classes.button} disabled={this.state.disabled}>
                        Back
                      </Button>
                    )}
                 
                    <Button
                      variant="contained"
                      color="primary"
                      align="right"
                      onClick={this.handleNext}
                      disabled={this.state.disabled}
                      className={classes.button}
                    >
                      {this.state.activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                    </Button>

                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </main>
        <Copyright />
       <HideFooter />
      </React.Fragment>
    )
  }
}

{/* <Route component={SeasonParkingExtension} />; */ }

export default withRouter(withStyles(styles)(SeasonParkingApplication))
