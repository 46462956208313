import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { FormText } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: 'id', headerName: 'ID' },
  { field: 'iu', headerName: 'IU', width: 150, editable: false },
  { field: 'plate', headerName: 'Plate', width: 150, editable: false }
]

const secondColumns = [
  { field: 'id', headerName: 'ID' },
  { field: 'iu', headerName: 'IU', width: 150, editable: true },
  { field: 'plate', headerName: 'Plate', width: 150, editable: true }
]

const months = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 }
];

const validIURegex = RegExp(
  /^\d+$/
);

export class SecondApplicationDetail extends Component {
  constructor(pros) {
    super(pros);
    this.state = {
      secondRecord: [
        {
          id: 2,
          iu: '',
          plate: '',
        }
      ],
      errors: {
        plateAndIU: "",
        iu:""
      }
    };
  }

  componentDidMount() {
    if(this.props.isFirstTime == 1)
    {
      this.setState({
        secondRecord:this.props.secondRecord,
        errors: this.props.errors
      });
    }
  }

  postBackToMain(){
    let childData = {
      secondRecord : this.state.secondRecord,
      isFirstTime : 1,
      errors: this.state.errors
    };
    this.props.parentCallback(childData);
  }

  componentWillUnmount(){
    this.postBackToMain();
  }

  handleCellEditCommit = (params, event) => {
    let errors = this.state.errors;

    const array = this.state.secondRecord.map(r => {
      if (r.id === params.id) {
        return { ...r, [params.field]: params.value }
      } else {
        return { ...r }
      }
    });
  
    errors.plateAndIU = "";
    if(!validIURegex.test(array[0].iu) || array[0].iu.length != 10)
        errors.plateAndIU = "IU or plate is not valid, IU should be 10 digits, plate should not be empty!";
        
      if(array[0].plate.length == 0)
        errors.plateAndIU = "IU or plate is not valid, IU should be 10 digits, plate should not be empty!";
    this.setState({ 
      secondRecord: array,
      errors: errors
     });
     this.postBackToMain();
  };
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'iu': 
      errors.iu = 
        (value.length ===0||value.length !=10)
          ? "iu is not valid or not 10 char!"
          : "";
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state.secondRecord[0].iu=value; 
      break;
      case 'plate': 
      errors.plateAndIU = 
        value.length ===0
          ? "Company Name is not valid!"
          : "";
          this.state.secondRecord[0].plate=value.toUpperCase();
      break;
    default:
      break;
    }
    this.setState({errors:errors})
    this.postBackToMain();
  }
  render() {
    let startDate = moment(this.props.startDate).format('YYYY-MM-DD');
    let endDate = moment(this.props.endDate).format('YYYY-MM-DD');
    let iu=this.state.secondRecord[0].iu;
    let plate=this.state.secondRecord[0].plate;
    const rows = this.props.records;
    const secondRow = this.state.secondRecord;

    let errors = this.state.errors;
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              disabled="true"
              id="outlined-required"
              label="Season ID"
              defaultValue=""
              value={this.props.seasonid}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: '10%', width: '100%' }}>
              <DataGrid disabled='true' rows={rows} columns={columns} hideFooter='true' autoHeight='true' />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled="true"
              id="seasonParkingStartDate"
              name="seasonParkingStartDate"
              label="Current Season Parking Start Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={startDate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled="true"
              id="seasonParkingEndDate"
              name="seasonParkingEndDate"
              label="Current Season Parking End Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={endDate}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h5">
              Second Car*
            </Typography>
            {/* {
              errors.plateAndIU && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{errors.plateAndIU}</FormText>
            }
            <div style={{ height: '10%', width: '100%' }} FormLabel='Second Car'>
              <DataGrid aria-label='Second Car' rows={secondRow} columns={secondColumns} hideFooter='true' autoHeight='true' onCellEditCommit={this.handleCellEditCommit} />
            </div> */}
          </Grid>
          <Grid item xs={12}>
            {/* {
              errors.plateAndIU && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{errors.plateAndIU}</FormText>
            }
            <br /> */}
            {/* <FormGroup>
              <FormControlLabel control={<Checkbox onChange={this.handleCheckChange} checked={checked} />} label="Add Second Car?" />
            </FormGroup> */}
            {/* <div style={{ height: '10%', width: '100%' }}>
              <DataGrid rows={rows} columns={columns} hideFooter='true' autoHeight='true' ref={x=>x} onCellEditCommit={this.handleCellEditCommit}  />
            </div> */}
             <TextField
              id="iu"
              name="iu"
              label="IU"
              fullWidth
              autoComplete="shipping address-line2"
              value={iu}
              onChange={this.handleChange}
              required="true"
              error={errors.iu.length > 0}
              helperText={errors.iu.length > 0 ? errors.iu : ' '}
            />
          </Grid>
          <Grid item xs={12}>
             <TextField
              id="plate"
              name="plate"
              label="Car Plate NO"
              fullWidth
              autoComplete="shipping address-line2"
              value={plate}
              onChange={this.handleChange}
              required="true"
              error={errors.plateAndIU.length > 0}
              helperText={errors.plateAndIU.length > 0 ? errors.plateAndIU : ' '}
            />
          </Grid>
          <FormText style={{ color: 'rgb(9, 143, 175)' }}> <p>* By submitting this application, you consent to the collection, use 
and disclosure of your personal data, and if applicable, that you 
have been duly authorised by the individual that you are 
submitting this application on behalf of, to give his/her consent 
for the collection, use or disclosure of his/her personal data.</p>
            </FormText>
        </Grid>
      </React.Fragment>
    )
  }
}
