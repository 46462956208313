import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';


export class ExtensionReview extends Component {
  constructor(pros) {
    super(pros);
    this.state = {
      fee: 0
    };
  }
  render(){
    let startdate = moment(this.props.startdate).format('YYYY-MM-DD');
    let enddate = moment(this.props.enddate).format('YYYY-MM-DD');
    let newEndDate = moment(this.props.newEndDate).format('YYYY-MM-DD');
    let newStartDate=moment(this.props.RenewalStartdate).format('YYYY-MM-DD');
    let nameLength = this.props.name.length;
    let encryptName = this.props.name.substr(0, 2) + "******" + this.props.name.substr(nameLength - 2, nameLength);
    
    let phoneLength = this.props.phone.length;
    let encryptPhone = this.props.phone.substr(0, 2) + "****" + this.props.phone.substr(phoneLength-2, phoneLength);

    let encryptEmails = "";
    let splittedEmails = this.props.email.split(",");
    splittedEmails.forEach((email) => {
      let emailSplit = email.split("@");
      if(encryptEmails){
        encryptEmails += ",";
      }
      encryptEmails += emailSplit[0].substr(0, 2) + "******@******" + emailSplit[1].substr(emailSplit[1].length-2, emailSplit[1].length);
    })
    
    return (
      <React.Fragment>
        {/* <Typography variant="h6" gutterBottom>
          Extend Season Parking
        </Typography> */}
        <Grid container spacing={3}>
        <Grid item xs={12}>
            <TextField
              required
              disabled="true"
              id="outlined-required"
              label="Season ID"
              defaultValue=""
              value={this.props.seasonid}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled="true"
              id="seasonParkingStartDate"
              name="seasonParkingStartDate"
              label="Previous Season Parking Start Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={startdate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled="true"
              id="seasonParkingEndDate"
              name="seasonParkingEndDate"
              label="Previous Season Parking End Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={enddate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled="true"
              id="seasonParkingskipMonths"
              name="seasonParkingskipMonths"
              label="Skip Months"
              fullWidth
              autoComplete="shipping address-line2"
              value={this.props.skipMonths}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="newStartDate"
              name="newStartDate"
              label="New Season Parking Start Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={newStartDate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="extendMonth"
              name="extendMonth"
              label="No. of Month(s) to Extend"
              fullWidth
              autoComplete="shipping address-line2"
              value={this.props.extensionMonth.value}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled="true"
              id="newSeasonParkingEndDate"
              name="newSeasonParkingEndDate"
              label="New Season Parking End Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={newEndDate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="name"
              name="name"
              label="Name"
              fullWidth
              autoComplete="shipping address-line2"
              value={encryptName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="email"
              name="email"
              label="Email"
              fullWidth
              autoComplete="shipping address-line2"
              value={encryptEmails}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="phone"
              name="phone"
              label="Phone"
              fullWidth
              autoComplete="shipping address-line2"
              value={encryptPhone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="companyName"
              name="companyName"
              label="Company Name"
              fullWidth
              autoComplete="shipping address-line2"
              value={this.props.companyname}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="totalChargeToPay"
              name="totalChargeToPay"
              label="Total Charge Payable"
              fullWidth
              autoComplete="shipping address-line2"
              value={this.props.fee}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  
}
