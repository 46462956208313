import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { Form, FormText } from "react-bootstrap";
import "./LoginForm.css";
import background from "./img/GreatWorld.jpg";
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { HideFooter } from './HideFooter.js';
import {COPYRIGHT_BUSINESSENTITIES,BUILDINGS,TRANSACTIONTYPES} from './Constants.js';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://shop.greatworld.com.sg/">
        {new Date().getFullYear()} {COPYRIGHT_BUSINESSENTITIES}
      </Link>{' '}

      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    align: 'right'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

class LoginForm extends Component {

  constructor(pros) {
    super(pros);
    this.state = {
      errorCode: -1,
      result: "fail",
      transactionType: 0,
      building: "",
      plate: "",
      recordNumber: "",
      QRCode: [],
      errors: {
        transactionType: "",
        building: "",
        plate: "",
        return: ""
      }
    };
    this.changePlate = this.changePlate.bind(this);
    this.routeChange = this.routeChange.bind(this);
  }

  handleChange = (building) => {
    let errors = this.state.errors;
    errors.building = "";
    this.setState({ 
      building: building,
      errors: errors 
    });
  };

  handleTransactionTypeChange = (transactionType) => {
    let errors = this.state.errors;
    errors.transactionType = "";
    this.setState({ transactionType: transactionType,
                    errors: errors}
    );
  };

  changePlate(event) {
    let errors = this.state.errors;
    errors.plate = "";
    this.setState({ 
      plate: event.target.value,
      errors: errors
      });
  }

  routeChange() {

    //Basic validation for user's input
    let { errors, errorCode, transactionType, building, plate } = this.state;
    let valid = true;
    transactionType = transactionType == 0 ? 0 : transactionType.value;
    building = building == "" ? "": building.value;

    if (transactionType == 0) {
      errors.transactionType = "Please select a transaction type.";
      valid = false;
    }

    if (building == "") {
      errors.building = "Please select a building."
      valid = false;
    }

    if (transactionType != 1 && plate == "") {
      errors.plate = "Please input a valid plate."
      valid = false;
    }

    if (valid == true) {
      //Call API to get data from user input
      const postData = {
        transactionType: transactionType,
        building: building,
        plate: plate
      };
      
      //Season Parking Application(First Car)
      if (postData.transactionType == 1) {
        console.log(transactionType + "  " + building);
        this.props.history.push({
          pathname: "/Container/SeasonParkingApplication/SeasonParkingApplication",
          state: {
            building: this.state.building.value
          }
        })
      }
      //Season Parking Application(Second Car)
      else if (postData.transactionType == 2) {
        fetch(process.env.REACT_APP_API + 'LoginCheck', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        })
          .then(data => data.json())
          .then(
            (data) => {
              //Result is ok
              if (data.result == "ok") {
                this.props.history.push({
                  pathname: "/Container/SeasonParkingSecondApplication/SeasonParkingSecondApplication",
                  state: {
                    building: this.state.building.value,
                    plate: this.state.plate
                  }
                });
              } else {
                //Result is fail
                console.log(data.recordNumber);
                if (data.recordNumber == null) {
                  errorCode = 0;
                  errors.return = "Can't find plate under select building.";
                }
                else if (data.recordNumber != null) {
                  errorCode = 1;
                  errors.return = "<p>You have an incomplete transaction. Please complete the previous transaction before making a new request.<br/><br/>"+
                  "If you would like to cancel the incomplete transaction, please send an email to <a href='it.support@allgreen.com.sg'>it.support@allgreen.com.sg</a>.</p>"
                }
                else {
                  errorCode = 2;
                  errors.return = "Season Parking expired. Please submit a new application instead."
                }
              }
              this.setState({
                result: data.result,
                recordNumber: data.recordNumber,
                QRCode: data.recordQR,
                errorCode: errorCode,
                errors: errors
              });
            },
            (error) => {
              this.setState({
                errors: 'Hit error in API call'
              });
            })
      }
      //Season Parking Renewal
      else if (postData.transactionType == 3) {
        fetch(process.env.REACT_APP_API + 'LoginCheck', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        })
          .then(data => data.json())
          .then(
            (data) => {
              //Result is ok
              if (data.result == "ok") {
                this.props.history.push({
                  pathname: "/Container/SeasonParkingExtension/SeasonParkingExtension",
                  state: {
                    building: this.state.building.value,
                    plate: this.state.plate
                  }
                });
              } else {
                //Result is fail
                if (data.recordNumber == null) {
                  errorCode = 0;
                  errors.return = "Can't find plate under select building.";
                }
                else if (data.recordNumber != null) {
                  errorCode = 1;
                  errors.return = "<p>You have an incomplete transaction. Please complete the previous transaction before making a new request.<br/><br/>"+
                  "If you would like to cancel the incomplete transaction, please send an email to <a href='it.support@allgreen.com.sg'>it.support@allgreen.com.sg</a>.</p>"
                }
                else {
                  errorCode = 2;
                  errors.return = "Season Parking expired. Please submit a new application instead."
                }
              }
              this.setState({
                result: data.result,
                recordNumber: data.recordNumber,
                QRCode: data.recordQR,
                errorCode: errorCode,
                errors: errors
              });
            },
            (error) => {
              errors.return = "Hit error in API.";
            })
      }
      //Season Parking Termination
      else if (postData.transactionType == 4) {
        fetch(process.env.REACT_APP_API + 'LoginCheck', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        })
          .then(data => data.json())
          .then(
            (data) => {
              //Result is ok
              if (data.result == "ok") {
                this.props.history.push({
                  pathname: "/Container/SeasonParkingTermination/SeasonParkingTermination",
                  state: {
                    building: this.state.building.value,
                    plate: this.state.plate
                  }
                });
              } else {
                //Result is fail
                if (data.recordNumber == null) {
                  errorCode = 0;
                  errors.return = "Can't find plate under select building.";
                }
                else if (data.recordNumber != null) {
                  errorCode = 1;
                  errors.return = "<p>You have an incomplete transaction. Please complete the previous transaction before making a new request.<br/><br/>"+
                  "If you would like to cancel the incomplete transaction, please send an email to <a href='it.support@allgreen.com.sg'>it.support@allgreen.com.sg</a>.</p>"
                }
                else {
                  errorCode = 2;
                  errors.return = "Season Parking expired. Please submit a new application instead."
                }
              }
              this.setState({
                result: data.result,
                recordNumber: data.recordNumber,
                QRCode: data.recordQR,
                errorCode: errorCode,
                errors: errors
              });
            },
            (error) => {
              this.setState({
                errors: 'Hit error in API call'
              });
            })
      }
      //Season Parking Update
      else if (postData.transactionType == 5) {
        fetch(process.env.REACT_APP_API + 'LoginCheck', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        })
          .then(data => data.json())
          .then(
            (data) => {
              //Result is ok
              if (data.result == "ok") {
                this.props.history.push({
                  pathname: "/Container/SeasonParkingUpdate/SeasonParkingUpdate",
                  state: {
                    building: this.state.building.value,
                    plate: this.state.plate
                  }
                });
              } else {
                //Result is fail
                if (data.recordNumber == null) {
                  errorCode = 0;
                  errors.return = "Can't find plate under select building.";
                }
                else if (data.recordNumber != null) {
                  errorCode = 1;
                  errors.return = "<p>You have an incomplete transaction. Please complete the previous transaction before making a new request.<br/><br/>"+
                  "If you would like to cancel the incomplete transaction, please send an email to <a href='it.support@allgreen.com.sg'>it.support@allgreen.com.sg</a>.</p>"
                }
                else {
                  errorCode = 2;
                  errors.return = "Season Parking expired. Please submit a new application instead."
                }
              }
              this.setState({
                result: data.result,
                recordNumber: data.recordNumber,
                QRCode: data.recordQR,
                errorCode: errorCode,
                errors: errors
              });
            },
            (error) => {
              this.setState({
                errors: 'Hit error in API call'
              });
            })
      }
    }
    else
    {
      this.setState({
        errors: errors
      })
    }



  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {

    const { transactionType, building, plate, errors, errorCode } = this.state;
    const QRCode = "data:image/jpg;base64," + this.state.QRCode;
    const { classes } = this.props;

    let showQR = false;
    if (transactionType in [1, 2, 3] && errorCode == 1)
      showQR = true;

    return (
      <React.Fragment style={{ backgroundImage: `url(${background})` }}>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <div>
              <Form>
                <Typography align="center" variant="h5">
                  Season Parking Services
                </Typography>
                <br />
                <Form.Group size="lg" controlId="ApplicationType">
                  <FormText style={{ color: 'black' }}>Transaction Type *</FormText>
                  <Select
                    id="TransactionType"
                    fullWidth
                    name="TransactionType"
                    options={TRANSACTIONTYPES}
                    label="TransactionType"
                    value={transactionType}
                    onChange={this.handleTransactionTypeChange}
                  >
                  </Select>
                  {
                    errors.transactionType.length > 0 && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{errors.transactionType}</FormText>
                  }
                </Form.Group>
                <br />
                {transactionType.value != -1 &&
                  <Form.Group size="lg" controlId="Building">
                    <FormText style={{ color: 'black' }}>Building *</FormText>
                    <Select
                      id="Building"
                      fullWidth
                      name="Building"
                      options={BUILDINGS}
                      label="Building"
                      value={building}
                      onChange={this.handleChange}
                    >
                    </Select>
                    {
                      errors.building.length > 0 && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{errors.building}</FormText>
                    }
                  </Form.Group>}
                <br />

                {transactionType.value != 1 &&
                  <Form.Group size="lg" controlId="Plate">
                    <TextField
                      required
                      id="carPlateNo"
                      name="carPlateNo"
                      label="Car Plate No."
                      fullWidth
                      autoComplete="shipping address-line1"
                      value={plate}
                      onChange={this.changePlate}
                    />
                    {
                      errors.plate.length > 0 && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{errors.plate}</FormText>
                    }
                  </Form.Group> 
                  }
                  {transactionType.value===2 &&
                   <Typography variant="subtitle2"> Please input your 1st car plate number in this building 
                   </Typography> 
                  }
                  {
                    this.state.building.value==="TM"&&transactionType.value===2&&
                    <Typography variant="subtitle2"> Application for 2nd car is for tenant only 
                    </Typography> 
                  }
                <br />

                <Grid container xs={12} alignItems="flex-end" justifyContent="flex-end" direction="column">
                  <Button variant="contained" color="primary" align="left" onClick={this.routeChange}>Next</Button>
                </Grid>
                <br />
                <Grid>
                  {
                    errors.return.length > 0 && <FormText style={{ color: 'red', fontWeight: 'bold' }}><div dangerouslySetInnerHTML={{__html:errors.return}}></div></FormText>
                  }
                  {
                    showQR && <img src={QRCode} alt="image"></img>
                  }
                </Grid>
              </Form>
            </div>
          </Paper>
        </main>
        <Copyright className="bottom" />
        <HideFooter />
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(LoginForm));


