import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { TerminationDetail } from './TerminationDetail';
import { TerminationReview } from './TerminationReview';
import { withStyles } from '@material-ui/core/styles';
import { Route, withRouter } from 'react-router-dom';
import { Navigation } from '../../Navigation';
import { HideFooter } from '../../HideFooter.js';
import { COPYRIGHT_BUSINESSENTITIES, ERRORMESSAGE, RECAPTCHA_ACTIONS } from '../../Constants.js';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://shop.greatworld.com.sg/">
        {new Date().getFullYear()} {COPYRIGHT_BUSINESSENTITIES}
      </Link>{' '}

      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    align: 'right'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

const steps = ['Details', 'Review your order'];

const validateForm = errors => {
  let valid = true;
  for (const [key, value] of Object.entries(errors)) {
    if(value.length > 0)
      valid = false;
  }
  return valid;
};

class SeasonParkingTermination extends Component {
  constructor(pros) {
    super(pros);
    this.state = {
      activeStep: 0,

      building: "",
      seasonId: "",
      startDate: "",
      endDate: "",
      terminationDate: "",
      terminationReason: -1,
      refundAmount: 0,
      seasontype: 0,
      name: "",
      phone: "",
      email: "",
      companyName: "",
      carCount: 0,
      categoryid: "",
      records: [],
      isFirstTime: 0,
      remark:"",
      payeeName:"",
      reCaptchaToken: "",
      disabled: false,
      errors:{
        terminationDate: "",
        terminationReason: "",
        remark:"",
        payeeName:""
      }
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    const postData = { building: this.props.location.state.building, plate: this.props.location.state.plate };

    fetch(process.env.REACT_APP_API + 'GetSeasonParkingInfo', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then(data => data.json())
      .then((data) => {
        var carCount = Object.keys(data.records).length;

        this.setState({
          building: postData.building,
          seasonId: data.seasonid,
          startDate: data.startdate,
          endDate: data.enddate,
          seasontype: data.seasontype,
          name: data.name,
          phone: data.phone,
          email: data.email,
          companyName: data.companyname,
          carCount: carCount,
          categoryid: data.categoryid,
          records: data.records
        });
      })
  }

  handleNext() {

    //Validate field before proceed to Review page
    let errors = this.state.errors;

    if(this.state.terminationDate == "")
      errors.terminationDate = "Field is required"
    if(this.state.terminationReason == null)
      errors.terminationReason = "Field is required"
    if(this.state.payeeName == null)
      errors.payeeName = "Field is required"
    if( validateForm(errors) == false )
      {
       this.setState({
         errors: errors
       }); 
       return;
      }

    if(this.state.disabled)
      return;
    
    console.log(this.state.terminationReason.label);
    //Create Season Parking Termination
    if (this.state.activeStep == 1) {
    this.setState({ disabled: true })
      //continue after getting recaptcha token
      new Promise(function(resolve,reject){
        try{
          window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: RECAPTCHA_ACTIONS.SeasonParkingTerminate }).then(token => {
            resolve(token);
          })
        }catch(error){
          reject(error);
        }
      }).catch(() => {
        alert(ERRORMESSAGE.recaptcha);
        this.setState({                
          disabled: false
        })
      }).then((value)=>{
        this.setState({reCaptchaToken : value});
        const terminationData = {
          building: this.state.building,
          seasonId: this.state.seasonId,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          terminationDate: this.state.terminationDate,
          terminationReason: this.state.terminationReason.label,
          PayeeName:this.state.payeeName,
          remark:this.state.remark,
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          companyName: this.state.companyName,
          records: this.state.records,
          recaptchaToken : this.state.reCaptchaToken,
          refundAmount: this.state.refundAmount,
        };
      
        fetch(process.env.REACT_APP_API + 'TerminationParking', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(terminationData)
        })
        .then(data => data.json())
        .then((data) => {
          if (data.result === "ok") {
            this.setState({
              activeStep: this.state.activeStep + 1,
              disabled: false
            });
          } else {
            //Result is fail  
            alert(data.Message)
            this.state.activeStep = 0;
            this.setState({
              disabled: false
            });
          } 
        })
      })
    } else {
      this.setState({
        activeStep: this.state.activeStep + 1
      });
    }
  }

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    });
  }

  callbackDetail = (childData) => {
    this.setState({
      terminationDate: childData.terminationDate,
      terminationReason: childData.terminationReason,
      refundAmount: childData.refundAmount,
      isFirstTime: childData.isFirstTime,
      remark:childData.remark,
      payeeName:childData.payeeName,
      errors: childData.errors
    });
  }

  getStepContent(step, callbackDetail) {
    switch (step) {
      case 0:
        return <TerminationDetail
          seasonId={this.state.seasonId}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          building={this.state.building}
          carCount={this.state.carCount}
          categoryid={this.state.categoryid}
          records={this.state.records}
          terminationDate={this.state.terminationDate}
          terminationReason={this.state.terminationReason}
          refundAmount={this.state.refundAmount}
          isFirstTime={this.state.isFirstTime}
          errors={this.state.errors}
          payeeName={this.state.payeeName}
          remark={this.state.remark}
          parentCallback={callbackDetail} />;
      case 1:
        return <TerminationReview
          seasonId={this.state.seasonId}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          building={this.state.building}
          records={this.state.records}
          carCount={this.state.carCount}
          categoryid={this.state.categoryid}
          name={this.state.name}
          phone={this.state.phone}
          email={this.state.email}
          payeeName={this.state.payeeName}
          remark={this.state.remark}
          companyName={this.state.companyName}
          terminationDate={this.state.terminationDate}
          terminationReason={this.state.terminationReason}
          refundAmount={this.state.refundAmount} />;
      default:
        throw new Error('Unknown step');
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Navigation />
        <CssBaseline />
        <AppBar position="absolute" color="default" className={classes.appBar}>
          {/* <Toolbar>
            <Typography variant="h6" color="inherit" noWrap>
              Season Parking Extension
            </Typography>
          </Toolbar> */}
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Season Parking Termination
            </Typography>
            <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {this.state.activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                  Your termination request has been submitted.
                  </Typography>
                  <Typography variant="subtitle1">
                  You will be receiving a confirmation email in your inbox that requires further action from you. Once you have confirmed the termination request, your season parking will be terminated from the selected month(s).
                  </Typography>
                  <br />
                  <Typography variant="subtitle1">
                    For further query, please contact it.support@allgreen.com.sg.
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.getStepContent(this.state.activeStep, this.callbackDetail)}

                  <div className={classes.buttons}>

                    {this.state.activeStep !== 0 && (
                      <Button onClick={this.handleBack} className={classes.button} disabled={this.state.disabled}>
                        Back
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      align="right"
                      disabled={this.state.disabled}
                      onClick={this.handleNext}
                      className={classes.button}
                    >
                      {this.state.activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                    </Button>

                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </main>
        <Copyright />
        <HideFooter />
      </React.Fragment>
    )
  }
}

{/* <Route component={SeasonParkingExtension} />; */ }

export default withRouter(withStyles(styles)(SeasonParkingTermination))
