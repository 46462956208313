import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { FormText } from "react-bootstrap";
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DataGrid } from "@mui/x-data-grid";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const columns = [
  { field: 'id', headerName: 'ID' },
  { field: 'iu', headerName: 'IU', width: 150, editable: false },
  { field: 'plate', headerName: 'Plate', width: 150, editable: false }
]

const changeColumns = [
  { field: 'id', headerName: 'ID' },
  { field: 'iu', headerName: 'IU', width: 150, editable: true },
  { field: 'plate', headerName: 'Plate', width: 150, editable: true }
]

const months = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 }
];
const validIURegex = RegExp(
  /^\d+$/
);
export class UpdateDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seasonId: "",
      startDate: "",
      endDate: "",
      records: [],

      changeType: "tempcar",
      changeStartDate: "",
      changeEndDate: "",
      changeMonth: 0,
      changeRecords: [
        {
          id: 1,
          iu: '',
          plate: '',
        }
      ],
      errorCode: -1,
      errors: {
        noOfChange: "",
        plateAndIU: "Field is required",
        iu:"Field is required" ,
        startDate:"Field is required",
        enddate:"Field is required"
      }
    };
  }
  componentDidMount() {
    console.log("ComponentDidMount " + this.props.isFirstTime);
    if (this.props.isFirstTime == 1) {
      this.setState({changeType : this.props.changeType,
                     changeStartDate : this.props.changeStartDate,
                     changeEndDate : this.props.changeEndDate,
                     changeMonth : this.props.changeMonth,
                     changeRecords : this.props.changeRecords
                   });
    }
  }
  postBackToMain(){
    let childData = {
      changeType: this.state.changeType,
      changeStartDate : this.state.changeStartDate,
      changeEndDate : this.state.changeEndDate,
      changeMonth : this.state.changeMonth,
      changeRecords : this.state.changeRecords,
      errors:this.state.errors,
      isFirstTime: 1
    };
    this.props.parentCallback(childData);
  }
  componentWillUnmount() {
    let childData = {
      changeType: this.state.changeType,
      changeStartDate : this.state.changeStartDate,
      changeEndDate : this.state.changeEndDate,
      changeMonth : this.state.changeMonth,
      changeRecords : this.state.changeRecords,
      errors:this.state.errors,
      isFirstTime: 1
    };
    this.props.parentCallback(childData);
  }

  changeApplicationMonth = (applicationMonth) => {
    let applicationMonthInt = parseInt(applicationMonth.value, 10) - 1;

    var changeStartDate = moment(this.state.changeStartDate);
    let changeEndDate = changeStartDate.add(applicationMonthInt, 'month').endOf('month');

    let startDate = moment(this.props.startDate);
    let endDate = moment(this.props.endDate);
    let error=this.state.errors;
    if(changeEndDate < startDate || changeEndDate > endDate)
    {error.enddate="Invalid Change End Date, please select a date between current start date and end date.";
      this.setState({
        changeMonth: 0,
        changeEndDate: "",
        errorCode:2,
        errors : error});
      return;
    }
    else
    {
      this.setState({
        changeMonth: applicationMonth,
        changeEndDate: moment(changeEndDate).format('YYYY-MM-DD'),
        errorCode: -1,
        errors : error});
    }
  }

  handleChangeStartDate = (newvalue)=> {
    
    var changeStartDate = moment(newvalue);
    var changeStartDateString = moment(newvalue).format('YYYY-MM-DD');

    let startDate = moment(this.props.startDate);
    let endDate = moment(this.props.endDate);
    let errors=this.state.errors;
    let today=moment(new Date()).format('YYYY-MM-DD'); 
    if(changeStartDate < startDate || changeStartDate > endDate||moment(today)>moment(changeStartDateString))
    {
      errors.startDate="Invalid Change Start Date, please select a date between current start date and end date,and not earlier today.";
      this.setState({
        changeStartDate: "",
        errorCode: 1,
        errors : errors});
      return;
    }
    else  
    {
      errors.startDate="";
      this.setState({
        changeStartDate: changeStartDateString,
        errorCode: -1,
        errors : errors});
    }
    this.postBackToMain();
  }
  handleChangeEndDate = (newvalue)=> {
    
    var changeEndtDate = moment(newvalue);
    var changeEndDateString = moment(newvalue).format('YYYY-MM-DD');
    let changeStart=this.state.changeStartDate;
    let startDate = moment(this.props.startDate);
    let endDate = moment(this.props.endDate);
    let errors=this.state.errors;
    if(changeEndtDate < startDate || changeEndtDate > endDate||!moment(changeStart).isBefore(changeEndDateString))
    {
      errors.enddate="Invalid Change End Date, please select a date between current start date and end date.";
      this.setState({
        changeEndDate: "",
        errorCode: 2,
        errors : errors});
      return;
    }
    else  
    {
      errors.enddate="";
      this.setState({
        changeEndDate: changeEndDateString,
        errorCode: -1,
        errors : errors});
    }
    this.postBackToMain();
  }
  handleCellEditCommit = (params, event) => {
    let errors=this.state.errors;
    const array = this.state.changeRecords.map(r => {
      if (r.id === params.id) {
        return { ...r, [params.field]: params.value }
      } else {
        return { ...r }
      }
    });

     //Validate IU and Plate
     errors = "";
     if(!validIURegex.test(array[0].iu) || array[0].iu.length != 10)
     errors = "IU or plate is not valid, IU should be 10 digits, plate should not be empty!";
     
   if(array[0].plate.length == 0)
     errors = "IU or plate is not valid, IU should be 10 digits, plate should not be empty!";
     this.setState({ changeRecords: array,errors:errors });
  };
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let recode=this.state.changeRecords;
    switch (name) {
      case 'iu': 
      errors.iu = 
        (value.length ===0||value.length !=10)
          ? "iu is not valid or not 10 char!"
          : "";
          recode[0].iu=value; 
      break;
      case 'plate': 
      errors.plateAndIU = 
        value.length ===0
          ? "Company Name is not valid!"
          : "";
          recode[0].plate=value.toUpperCase();
      break;
    default:
      break;
    }
    this.setState({changeRecords:recode,errors:errors})
    this.postBackToMain();
  }
  render() {
    let startDate = moment(this.props.startDate).format('YYYY-MM-DD');
    let endDate = moment(this.props.endDate).format('YYYY-MM-DD');
    let rows = this.props.records;

    let changeStartDate = this.state.changeStartDate;
    let changeEndDate = this.state.changeEndDate;
    let changeMonth = this.state.changeMonth;
    let changeType = this.state.changeType;
    let changeRows = this.state.changeRecords;
    let errors = this.state.errors;
    let errorCode = this.state.errorCode;
    let iu=this.state.changeRecords[0].iu;
    let plate=this.state.changeRecords[0].plate;
    //const columns = [{field:'Plate',width:150}, {field:'IU', width:150}];
    //const {extensionMonth} = this.state;
    //const records = [{ "plate": "plate 1", "iu": "iu 1" }, { "plate": "plate 2", "iu": "iu 2" }];
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              disabled="true"
              id="outlined-required"
              label="Season ID"
              defaultValue=""
              value={this.props.seasonId}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: '10%', width: '100%' }}>
              <DataGrid disabled='true' rows={rows} columns={columns} hideFooter='true' autoHeight='true' />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h5">
              Change IU/Plate*
            </Typography> 
          </Grid> 
          <Grid item xs={12}>
             <TextField
              id="iu"
              name="iu"
              label="IU"
              fullWidth
              autoComplete="shipping address-line2"
              value={iu}
              onChange={this.handleChange}
              required="true"
              error={errors.iu.length > 0}
              helperText={errors.iu.length > 0 ? errors.iu : ' '}
            />
          </Grid>
          <Grid item xs={12}>
             <TextField
              id="plate"
              name="plate"
              label="Car Plate No."
              fullWidth
              autoComplete="shipping address-line2"
              value={plate}
              onChange={this.handleChange}
              required="true"
              error={errors.plateAndIU.length > 0}
              helperText={errors.plateAndIU.length > 0 ? errors.plateAndIU : ' '}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled="true"
              id="seasonParkingStartDate"
              name="seasonParkingStartDate"
              label="Current Season Parking Start Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={startDate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              id="seasonParkingEndDate"
              name="seasonParkingEndDate"
              label="Current Season Parking End Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={endDate}
              disabled={this.state.changeType==="Temp"?true:false}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Change Type</FormLabel>
              <RadioGroup
                row
                aria-label="gender"
                name="controlled-radio-buttons-group"
                value={changeType}
                defaultValue={"tempcar"}
                onChange={(event) => {
                  this.setState({ changeType: event.target.value });
                  this.state.changeType=event.target.value;
                  this.postBackToMain();
                }}
              >
                <FormControlLabel value="tempcar" control={<Radio />} label="Temporary" />
                <FormControlLabel value="permanentcar" control={<Radio />} label="Permanent" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Change Start Date*"
                views={['year', 'month','day']}
                value={changeStartDate}
                onChange={this.handleChangeStartDate}
                inputFormat="dd-MM-yyyy"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <br/>
            {
              errorCode == 1 && errors && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{errors.startDate}</FormText>
            }
          </Grid>

          {/* {changeType == "tempcar" &&
            <Grid item xs={12}>
              <FormText>No. of Month(s)*</FormText>
              <Select
                id="changeMonth"
                name="changeMonth"
                label="No. of Month(s) to Apply"
                fullWidth
                options={months}
                value={changeMonth}
                onChange={this.changeApplicationMonth}
                disabled={this.state.changeType==="Temp"?true:false}
              />
            </Grid>} */}
          {changeType == "tempcar" &&
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker 
                  label="Change End Date*"
                  value={changeEndDate}
                  views={['year', 'month','day']}
                  onChange={this.handleChangeEndDate}
                  inputFormat="dd-MM-yyyy"
                  renderInput={(params) => <TextField {...params} />}
                  disabled={this.state.changeType==="Temp"?true:false}
                />
              </LocalizationProvider>
              <br/>
            {
              errorCode == 2 && errors && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{errors.enddate}</FormText>
            }
            </Grid>
          } 
        </Grid>
      </React.Fragment>
    )
  }
}
