import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DataGrid } from "@mui/x-data-grid";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const columns = [
  { field: 'id', headerName: 'ID' },
  { field: 'iu', headerName: 'IU', width: 150, editable: false },
  { field: 'plate', headerName: 'Plate', width: 150, editable: false }
]

export class UpdateReview extends Component {
  constructor(pros) {
    super(pros);
    this.state = {
    };
  }

  render(){

    let startDate = moment(this.props.startDate).format('YYYY-MM-DD');
    let endDate = moment(this.props.endDate).format('YYYY-MM-DD');

    let rows = this.props.records;
    let changeRows = this.props.changeRecords;

    // let nameLength = this.props.name.length;
    // let encryptName = this.props.name.substr(0, 2) + "******" + this.props.name.substr(nameLength - 2, nameLength);
    
    // let phoneLength = this.props.phone.length;
    // let encryptPhone = this.props.phone.substr(0, 2) + "****" + this.props.phone.substr(phoneLength-2, phoneLength);

    // let emailSplit = this.props.email.split("@");
    // const encryptEmail = emailSplit[0].substr(0, 2) + "******@******" + emailSplit[1].substr(emailSplit[1].length-2, emailSplit[1].length);

    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              disabled="true"
              id="outlined-required"
              label="Season ID"
              defaultValue=""
              value={this.props.seasonId}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: '10%', width: '100%' }}>
              <DataGrid disabled='true' rows={rows} columns={columns} hideFooter='true' autoHeight='true' />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled="true"
              id="seasonParkingStartDate"
              name="seasonParkingStartDate"
              label="Current Season Parking Start Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={startDate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled="true"
              id="seasonParkingEndDate"
              name="seasonParkingEndDate"
              label="Current Season Parking End Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={endDate}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Change Type</FormLabel>
              <RadioGroup
                disabled
                row
                aria-label="gender"
                name="controlled-radio-buttons-group"
                value={this.props.changeType}
              >
                <FormControlLabel value="tempcar" control={<Radio />} label="Temp" />
                <FormControlLabel value="permanentcar" control={<Radio />} label="Permanent" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled
                label="Change Start Date*"
                views={['year', 'month','day']}
                inputFormat="dd-MM-yyyy"
                value={this.props.changeStartDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>

          {/* {this.props.changeType == "tempcar" &&
            <Grid item xs={12}>
              <FormText>No. of Month(s)*</FormText>
              <Select
                isDisabled
                id="changeMonth"
                name="changeMonth"
                label="No. of Month(s) to Apply"
                fullWidth
                value={this.props.changeMonth}
              />
            </Grid>} */}
          {this.props.changeType == "tempcar" &&
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disabled
                  label="Change End Date*"
                  value={this.props.changeEndDate}
                  views={['year', 'month','day']}
                  inputFormat="dd-MM-yyyy"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          }
          {/* <Grid item xs={12}>
            <Typography align="center" variant="h5">
              Change IU/Plate*
            </Typography>
            <div style={{ height: '10%', width: '100%' }}>
              <DataGrid rows={changeRows} columns={columns} hideFooter='true' autoHeight='true' />
            </div>
          </Grid> */}
          <Grid item xs={12}>
             <TextField
              id="iu"
              name="iu"
              label="IU"
              fullWidth
              autoComplete="shipping address-line2"
              value={changeRows.length>0?changeRows[0].iu:""} 
              disabled
            />
          </Grid>
          <Grid item xs={12}>
             <TextField
              id="plate"
              name="plate"
              label="Car Plate No."
              fullWidth
              autoComplete="shipping address-line2"
              value={changeRows.length>0?changeRows[0].plate:""} 
              disabled
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
  
}
