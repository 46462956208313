/**
 * This file, Constants.js, contains the constants used 
 * within the SeasonParking React Application 
 */

// Function to get Building Constants based on build mode
let bindBuildings = function () {
  let Buildings = [];
  Buildings = [
    { label: "GREAT WORLD", value: "GWC" },
    { label: "TANGLIN MALL", value: "TM" },
    { label: "PASIR RIS MALL", value: "PRIS" }
  ];
  return Buildings
}

// Building Constant
let BUILDINGS = bindBuildings();

// Transaction Types constants
const TRANSACTIONTYPES = Object.freeze([
  { label: "Season Parking Application (for 1st Vehicle)", value: 1 },
  { label: "Season Parking Application (for 2nd Vehicle)", value: 2 },
  { label: "Season Parking Renewal", value: 3 },
  { label: "Season Parking Termination", value: 4 },
  { label: "Season Parking Change Of IU / Plate Number", value: 5 }
]);


//Application
const COLUMNS = Object.freeze([
  { field: 'id', headerName: 'ID' },
  { field: 'iu', headerName: 'IU', width: 150, editable: true },
  { field: 'plate', headerName: 'Plate', width: 150, editable: true }
]);

// Email Validation Regex constant
const VALIDEMAILREGEX = RegExp(
  /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,30})+)$/
);

// IU REGEX Constant
const VALIDIUREGEX = RegExp(
  /^\d+$/
);

// Review Steps Constants
const STEPS = ['Details', 'Review your order'];

//Error Messages
const ERRORMESSAGE = {
  startDate: "Invalid Start Month, please select current or future month.",
  plateAndIU: "IU or plate is not valid, IU should be 10 digits, plate should not be empty!",
  useName: "Name must be at least 2 characters long!",
  email: "Email is not valid!",
  phone: "Phone must be at least 8 characters long!",
  company: "Company Name is not valid!",
  iu: "iu is not valid or not 10 char!",
  plate: "plate is not valid!",
  selectRenewalDate: "Plase Select Renewal Date",
  RenwalStarDate: "RenewalStartDate cannot be more than 3 months apart from EndDate.",
  recaptcha: "Oops! There was an error with reCAPTCHA. Please try again later or contact support for assistance."
}

const RECAPTCHA_ACTIONS = 
{
  SeasonParkingApplication : "SeasonParkingApplication",
  SeasonParkingSecondApplication : "SeasonParkingSecondApplication",
  SeasonParkingUpdate : "SeasonParkingUpdate",
  SeasonParkingTerminate : "SeasonParkingTerminate",
  SeasonParkingRenewal : "SeasonParkingRenewal"
}

const MAX_RENEWAL_MONTHS = 3;

const COPYRIGHT_BUSINESSENTITIES = "GWC Commercial Pte Ltd | Cuscaden Properties Pte Ltd | Phoenix Commercial Pte Ltd";

export { COPYRIGHT_BUSINESSENTITIES, BUILDINGS, TRANSACTIONTYPES, COLUMNS, VALIDEMAILREGEX, VALIDIUREGEX, ERRORMESSAGE, STEPS, MAX_RENEWAL_MONTHS, RECAPTCHA_ACTIONS }
