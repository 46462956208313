import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: 'id', headerName: 'ID' },
  { field: 'iu', headerName: 'IU', width: 150, editable: false },
  { field: 'plate', headerName: 'Plate', width: 150, editable: false }
]

export class SecondApplicationReview extends Component {
  constructor(pros) {
    super(pros);
    this.state = {
    };
  }

  render(){

    let startDate = moment(this.props.startDate).format('YYYY-MM-DD');
    let endDate = moment(this.props.endDate).format('YYYY-MM-DD');
    let name = this.props.name;
    let phone = this.props.phone
    let email = this.props.email;
    let companyName = this.props.companyName;
    

    let carCount = this.props.carCount;
    let rows = this.props.records;
    let secondRow = this.props.secondRecord;

    // let nameLength = this.props.name.length;
    // let encryptName = this.props.name.substr(0, 2) + "******" + this.props.name.substr(nameLength - 2, nameLength);
    
    // let phoneLength = this.props.phone.length;
    // let encryptPhone = this.props.phone.substr(0, 2) + "****" + this.props.phone.substr(phoneLength-2, phoneLength);

    // let emailSplit = this.props.email.split("@");
    // const encryptEmail = emailSplit[0].substr(0, 2) + "******@******" + emailSplit[1].substr(emailSplit[1].length-2, emailSplit[1].length);

    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              disabled="true"
              id="outlined-required"
              label="Building"
              defaultValue=""
              value={this.props.building}
            />
          </Grid>

          <Grid item xs={12}>  
            <div style={{ height: '10%', width: '100%' }}>
              <DataGrid rows={rows} columns={columns} hideFooter='true' autoHeight='true'/>
            </div>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography align="center" variant="h5">
              Second Car*
            </Typography>
            <div style={{ height: '10%', width: '100%' }}>
              <DataGrid rows={secondRow} columns={columns} hideFooter='true' autoHeight='true' />
            </div>
          </Grid> */}
          <Grid item xs={12}>
            {/* {
              errors.plateAndIU && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{errors.plateAndIU}</FormText>
            }
            <br /> */}
            {/* <FormGroup>
              <FormControlLabel control={<Checkbox onChange={this.handleCheckChange} checked={checked} />} label="Add Second Car?" />
            </FormGroup> */}
            {/* <div style={{ height: '10%', width: '100%' }}>
              <DataGrid rows={rows} columns={columns} hideFooter='true' autoHeight='true' ref={x=>x} onCellEditCommit={this.handleCellEditCommit}  />
            </div> */}
             <TextField
              id="iu"
              name="iu"
              label="IU"
              fullWidth
              autoComplete="shipping address-line2"
              value={secondRow[0].iu} 
              disabled
            />
          </Grid>
          <Grid item xs={12}>
             <TextField
              id="plate"
              name="plate"
              label="Car Plate No."
              fullWidth
              autoComplete="shipping address-line2"
              value={secondRow[0].plate}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled
                label="Start Month*"
                views={['year', 'month']}
                value={startDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled
                label="End Month*"
                value={endDate}
                views={['year', 'month']}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="name"
              name="name"
              label="Name*"
              fullWidth
              autoComplete="shipping address-line2"
              value={name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="email"
              name="email"
              label="Email*"
              fullWidth
              autoComplete="shipping address-line2"
              value={email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="phone"
              name="phone"
              label="Phone*"
              fullWidth
              autoComplete="shipping address-line2"
              value={phone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="companyName"
              name="companyName"
              label="Company Name*"
              fullWidth
              autoComplete="shipping address-line2"
              value={companyName}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  
}
