import closeimg from './img/cross.png'
import React, {Component} from 'react';
var Modal = require('react-modal');

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      width                 :'80%',
      height                :'80%'
    }
  };

  export class HideFooter extends Component{
    state={modalIsOpen:false,
      modal2IsOpen:false}
        openModal=()=> {
    this.setState({modalIsOpen: true});
  } 
  closeModal=()=> {
    this.setState({modalIsOpen: false});
  } 
  openModal2=()=> {
    this.setState({modal2IsOpen: true});
  } 
  closeModal2=()=> {
    this.setState({modal2IsOpen: false});
  } 
    render(){
        return(
          <div style={{"background-color":"rgb(9, 143, 175)","text-align":"center","height":"80px","color":"white","line-height":"40px"}}>
          <Modal
            isOpen={this.state.modalIsOpen} 
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div style={{"text-align":"right"}}>
              <img src={closeimg} onClick={this.closeModal}></img>
            </div>
            <h1 ref="subtitle">Terms of Use (“Terms”)</h1>
  
            <p>A reference to “Allgreen Properties Limited”, “we”, “our”, or “us” in these Terms shall mean GWC Commercial Pte. Ltd. (201431989Z) in respect of Great World and Cuscaden Properties Pte. Ltd. (UEN: 199100466G) in respect of Tanglin Mall and Phoenix Commercial Pte. Ltd. (UEN: 201805800K) in respect of Pasir Ris Mall, and/or its related corporations and affiliates, collectively or singularly as the context requires.<br/><br/>
            A reference to the " website" in these Terms refers to the Season Parking Portal website available at https://carpark.allgreen.biz/.<br/><br/>
1.	Season Parking is available at Great World Tanglin Mall and Pasir Ris Mall (collectively, the <b>“Properties”</b>) at the rates shown when application(s) (including new or renewal applications) are made at the website.<br/><br/>

2.	Application(s) and payment should be made by the applicant (<b>“Season Parking User”</b>) before the start of intended month.<br/><br/>

3.	The season parking charges will not be pro-rated or refunded in the event there is any unused period which is less than one (1) calendar month.<br/><br/>

4.	Any requests for the refund of season parking charges which have been paid in advance for the full month will only be processed upon the complete submission of the online application (Request for Refund of Advance Payment for Season Parking (<b>“Request for Refund”</b>)) no later than the 25th day of the preceding month. Submission of the Request for Refund is strictly via online submission on the website only. Please note that we reserve the right not to process, approve, or consider any request for refund if it is not submitted online via the website or for any other reason(s) that may be determined in our sole discretion. <br/><br/>

5.	The availability of season parking facilities shall be based on allotment on a first-come-first-serve basis, which is to be determined at our sole discretion . We reserve the right to reject any application or remove any party without assigning any reason whatsoever and shall be at liberty to withdraw the existing season parking facilities by serving one (1) months’ notice in writing to all relevant parties. <br/><br/>

6.	We reserve the right to revise the season parking rates at any time as we may deem necessary, and the Season Parking Users shall pay any revised season parking charges to us on demand.<br/><br/>

7.	Goods and services tax (<b>“GST”</b>) means the prevailing tax rate chargeable under the Goods and Services Tax Act 1993.  All monthly season parking charges and any other sum(s) payable in relation to season parking are subject to prevailing GST, which shall be payable by the Season Parking User. <br/><br/>

8.	A Season Parking User shall be allowed to register only one (1) In-Vehicle Unit (IU) number for one (1) season parking application. <br/><br/>

9.	A Season Parking User who wishes to register two (2) In-Vehicle Unit numbers under one (1) season parking application shall be charged an additional fee of SGD$50.00 per month (subject to the prevailing GST). In the event that both vehicles are parked in the same building car park concurrently, the first vehicle that enters the car park shall be recognized as the Season Parking User; and the second vehicle shall be charged at the hourly parking rate. <br/><br/>

10.	We, our employees, servants, officers or agents reserve the right to verify the IU number of any Season Parking User, as and when we deem that it may be reasonably necessary to do so. <br/><br/>

11.	In the event that the Season Parking User’s IU is damaged, standard parking charges will apply and the Season Parking User accepts that there shall be no refund made for any unused period and the Season Parking User shall have no claims whatsoever against us. <br/><br/> 

12.	Request for change of IU number for season parking access shall be made through an online application (Permanent Change) or (Temporary Access) stating the reason for the change via the website only. The Season Parking User is only allowed to make changes up to a maximum of 3 times per calendar year.<br/><br/>

13.	<b>All season parking not RENEWED on time will be automatically terminated by the system.</b> Thereafter, the Season Parking User shall be required to submit an online application to renew the season parking via the website. <br/><br/>

14.	Vehicles found tailgating and exiting without making full payment shall be charged based on the hourly parking rates together with an administrative fee of SGD$100.00 (subject to the prevailing GST) per occasion. <br/><br/>

15.	Charging of batteries, washing, or repairing of vehicles are not allowed in the car parks except at the designated lots assigned by us.<br/><br/>

16.	Car park lots shall be available for Reserved / Season Parking Users only during the following designated hours:- <br/>
Mondays – Fridays 0700 – 1800 hrs <br/>
Saturdays 0700 – 1300 hrs <br/><br/>

Apart from the days and time periods stated above, season parking lots will be available for use by the public, except for car park lots marked as ‘All Day Reserved Lot’. This includes Public Holidays and Sundays.<br/><br/>

17.	The Season Parking User shall ensure that their vehicles shall not be parked at entrances / exits or locations that will obstruct traffic flow. Any vehicles causing any obstruction to traffic flow, parked outside the designated parking lots or in a wheel-clamp zone will be wheel-clamped. A fee of SGD$100.00 (subject to the prevailing GST) per day or part thereof shall be charged for the period that the vehicle is wheel-clamped in the car park. Wheel-clamped vehicles will be released only on receipt of full payment of such charges.<br/><br/>

18.	Vehicles are parked entirely at the risk of Season Parking Users. We shall not be held responsible for any damages, loss, theft or misdemeanor to the Season Parking Users’ vehicles, equipment or contents whilst parked in the car park premises. Season Parking Users shall be solely liable for any damages or injuries caused to the building or properties whether caused wholly or in part by the Season Parking Users. The Season Parking User hereby agrees that he/she shall indemnify and hold us harmless in full against all consequences, claims, liabilities, actions, proceedings, damages, costs, losses, fines or penalties of any kind that we may suffer, sustain or incur directly or indirectly as a result of any acts, omission, default or negligence of any users authorized or nominated by the said Season Parking User. The Season Parking User further undertakes to ensure that all its authorized or nominated users shall abide by the rules and regulations as set out herein. <br/><br/>

19.	Season Parking Users shall at all times observe and abide by the rules and regulations issued by us or such other directions / notices as displayed in the car park or otherwise notified to the users and shall follow the directions and instructions of any security or personnel appointed or engaged by us (collectively the <b>“Rules and Regulations”</b>).  <br/><br/>

20.	Infringement or non-compliance of any of the Rules and Regulations, including parking at the double yellow line or driving against traffic direction shall lead to the immediate withdrawal or termination of Season Parking rights, without any refund of the season parking charges or part thereof. <br/><br/>

21.	These a Terms are subject to amendment or revision at any time at our sole and absolute discretion.  Season Parking Users shall be bound to these amendments or revisions upon notification of the same being sent to them at their last known address.<br/><br/>
            </p>
          </Modal>
          <Modal
            isOpen={this.state.modal2IsOpen} 
            onRequestClose={this.closeModal2}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div style={{"text-align":"right"}}>
              <img src={closeimg} onClick={this.closeModal2}></img>
            </div>
            <h1 ref="subtitle">Privacy Policy</h1>
            <p><h3>PERSONAL INFORMATION</h3><br/>
            In relation to the season parking services provided by us to you, we may collect personal data (depending on the nature of your interaction with us) or other information from you such as:
  <br/><br/>
•	personal particulars (e.g. name, mobile number, email addresses, vehicle number, vehicle IU number, and company name);<br/>
•	transactional information based on your activities on our Season Parking Portal website (for e.g., renewal period(s), change of vehicle(s) and termination(s) of season parking);<br/>
•	additional information that we may ask you to submit for authentication or if we believe you are violating any policies on our website (for e.g., we may ask you to send us a copy of your identification card or bill to verify you are the tenant, or to answer additional questions online to help verify your identity as our tenant);<br/>
•	information about your use of the Season Parking Portal and related services, including cookies, Internet Protocol (“IP”) address and account details;<br/>
•	 any correspondence with you; and<br/>
•	personal opinions made known to us (for e.g., feedback or responses through surveys).<br/><br/><br/>

  <h3>PERSONAL INFORMATION COLLECTION AND USE</h3><br/><br/>
  Generally, we may collect your personal information in the following ways including but not limited to:<br/>
•	when you  submit an application with us through the Season Parking Portal;<br/>
•	when you make any transaction (for e.g., renewal, change and termination) through the Season Parking Portal ;<br/>
•	when you respond to our request for further personal information when interacting with our customer service personnel through telephone, email or other means of communication;<br/>
•	when you access or use the Season Parking Portal for any reason whatsoever;<br/>
•	when you contact us or request that we contact you for assistance;<br/>
•	when you respond to our request for feedback or surveys;<br/>
•	when you respond to our promotions and other marketing initiatives; or<br/>
•	when you disclose any personal information to us for any other reason.<br/><br/><br/>

  
  <h3>Submission through Season Parking Portal</h3><br/><br/>
  
•	declared that the particulars and information given are true and accurate;<br/>
•	consented to the collection, use and disclosure of your personal data by us in connection with the season parking services including any other reasonable purposes as may be appropriate; and<br/>
•	read, understood and agreed to be bound by the terms and conditions set-out herein.<br/><br/>

Once you submit an application through,  the Season Parking Portal (https://carpark.allgreen.biz), please note information about the configuration of your mobile phone such as your IP address, location, cookie information and the parts of the Season Parking Portal which you have accessed are available to us.<br/><br/>

<h3>Submission for Others through Season Parking Portal</h3><br/><br/>

When you submit an application through the Season Parking Portal on behalf of a third party such as family members and friends, or invite others to participate in our related services, we may collect the personal information you provide about such persons. <br/><br/>

By providing the personal information of such persons, you represent and warrant that the consent of such persons have been obtained for the collection and use of such information in accordance with this policy, which includes but not limited to the following:<br/><br/>

•	processing your application for your account with us, and facilitating, administrating, managing and/or maintaining your account;<br/>
•	keeping you updated on our announcements, software updates, policy updates, changes to the relevant terms and conditions with us and upcoming events;<br/>
•	creating, developing, operating, delivering and improving our services, content and advertising;<br/>
•	developing processes and technologies for loss prevention and prevention, detection and investigation of crimes, including fraud and money laundering;<br/>
•	for verification of identity, carrying out due diligence and other screening activities assisting with identification of users, and to determine appropriate services;<br/>
•	for delivery of important notices, such as communications about changes to our terms, conditions and policies;<br/>
•	for handling complaints and disputes and the conduct of relevant investigations and proceedings;<br/>
•	for internal purposes such as risk assessment, auditing, data and statistical analysis, and research;<br/>
•	for conducting research, analysis including data analytic, surveys and/or profiting to improve all services and products and/or otherwise for business operations and purposes;<br/>
•	protecting and enforcing our legal rights and obligations;<br/>
•	managing our business operations and to comply with internal policies and procedures;<br/>
•	to comply with any applicable laws, regulations, codes and/or guidelines;<br/>
•	to assist in law enforcement and investigations by relevant authorities; and<br/>
•	if you are a corporate body, in communicating with your staff regarding any of the above.<br/><br/>

  
  <h3>DISCLOSURE OF PERSONAL INFORMATION COLLECTED</h3><br/>
  
  We will not, without your permission, distribute or sell personal information provided by you to any unaffiliated third party, except under the following circumstances:<br/><br/>

•	for the purposes of providing products or services that you have requested or purchased;<br/>
•	monitoring products and services provided by or made available through the Season Parking Portal; <br/>
•	providing the personal information to our parent company, subsidiary, related, associated or affiliated company and/or any third-party service providers whom we have contracted to help with our business operations.  We may employ other companies and individuals to perform functions on our behalf. Examples include payment processing for purchases, sending marketing e-mails and short message services, analyzing data, providing marketing assistance, investigating frauds and providing customer service. They have access to personal information needed to perform their functions, but may not use it for any other purposes;<br/>
•	for the purposes of sharing personal information with companies, third party service providers or partners who provide services including, but not limited to, information processing, managing and enhancing customer data, providing customer service, assessing your interest in our services, and conducting customer research or satisfaction surveys. These companies are obligated to protect your personal information in accordance with applicable data protection and privacy laws;<br/>
•	complying with all applicable laws, regulations, rules, directives, orders, instructions and requests from any local or foreign authorities, including regulatory, governmental, tax and law enforcement authorities or other authorities;<br/>
•	financial reporting, regulatory reporting, management reporting, risk management (including monitoring credit exposures), audit and record keeping purposes; and<br/>
•	we may also disclose information about you if we determine that disclosure is reasonably necessary to enforce our terms and conditions or protect our operations or users.<br/><br/>

Where your personal information is to be disclosed to any third party, we shall do so in compliance with all applicable laws on data privacy, and we will require such third parties to ensure that any personal information disclosed to them are kept confidential and secure.<br/><br/>

For the avoidance of doubt, if we have reasonable grounds to believe that you are in breach of any of the terms of this policy, we reserve the right, in our sole and absolute discretion, to cooperate fully with governmental authorities, private investigators, all the rightful owner(s) or interest holder(s) and/or injured third parties in the investigation of any potential or on-going criminal or civil wrongdoing, without informing or contacting you beforehand.<br/><br/>

Further, we may disclose your identify and contact information, or such other transaction-related data, if requested by a government or law enforcement body, private investigator, rightful owner or interest holder and/or any injured third party or as a result of a subpoena or other legal action, or if we decide, in our sole and absolute discretion, that it would be in our best interests to do so. We shall not be liable for damages or results arising from such disclosure, and you agree not to bring action or claim against us for such disclosure.<br/><br/>

  </p>
          </Modal>
            | <a onClick={this.openModal} >Terms of use</a> | <a  onClick={this.openModal2}>Privacy Policy</a> |<br/><small>Version: {process.env.REACT_APP_BUILD_VERSION}</small>
            </div>
        )
    }
}