import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import { Navbar,Nav} from 'react-bootstrap';

export class Navigation extends Component{
    render(){
        return(
            <Navbar style={{backgroundColor:'#098FAF'}} expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                    <NavLink className="d-inline p-2 text-white" to="/LoginForm">Home</NavLink>
                    {/* <NavLink className="d-inline p-2 bg-primary text-white" to="/Container/SeasonParkingApplication/SeasonParkingApplication">Season Parking Application</NavLink> */}
                    {/* <NavLink className="d-inline p-2 bg-primary text-white" to="/Container/SeasonParkingExtension/SeasonParkingExtension">Season Parking Extension</NavLink> */}
                    {/* <NavLink className="d-inline p-2 bg-primary text-white" to="/Container/SeansonParkingTermination/SeasonParkingTermination">Season Parking Termination</NavLink>
                    <NavLink className="d-inline p-2 bg-primary text-white" to="/BasicInformation">Basic Information</NavLink>
                    <NavLink className="d-inline p-2 bg-primary text-white" to="/Update">Update Information</NavLink> */}
                </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}