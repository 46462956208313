import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Select from 'react-select';
import { Form, FormText } from "react-bootstrap";
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {VALIDEMAILREGEX,VALIDIUREGEX,ERRORMESSAGE, MAX_RENEWAL_MONTHS} from '../../Constants.js'

let noMonthRenewOptions=[];
export class ApplicationDetail extends Component {
  constructor(pros) {
    super(pros);
    this.state = {

      startDate: "",
      endDate: "",
      applicationMonth: 0,
      applicationType: "Public",
      ischangedType:0,
      units: "",

      name: "",
      phone: "",
      email: "",
      companyName: "",
      carCount: 1,
      records: [
        {
          id: 1,
          iu: '',
          plate: '',
        },
        {
          id: 2,
          iu: '',
          plate: '',
        }
      ],
      remark: "",
      errors: {
        startDate: "",
        applicationMonth:"",
        name: "",
        phone: "",
        email: "",
        companyName: "",
        plateAndIU: "",
        iu:""
      }
    }; 
  }

  componentDidMount() {
    if (this.props.isFirstTime == 1) {
      this.setState({
        building: this.props.building,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        applicationMonth: this.props.applicationMonth,
        applicationType: this.props.applicationType,
        units: this.props.units,
        name: this.props.name,
        email: this.props.email,
        phone: this.props.phone,
        companyName: this.props.companyName,
        carCount: this.props.carCount,
        records: this.props.records,
        remark: this.props.remark,
        errors: this.props.errors
      });
    }
    //Flush application months options and populate again
    noMonthRenewOptions = [];
    for(let i = 1; i <= MAX_RENEWAL_MONTHS; ++i)
    {
      let option={label:i,value:i}
      noMonthRenewOptions.push(option);
    }
  }

  postBackToMain(){
    let childData = {
      building: this.state.building,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      applicationMonth: this.state.applicationMonth,
      applicationType: this.state.applicationType,
      units: this.state.units,

      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      companyName: this.state.companyName,
      carCount: this.state.carCount,
      records: this.state.records,
      remark: this.state.remark,
      isFirstTime: 1,
      errors: this.state.errors
    };
    this.props.parentCallback(childData);
  }

  componentWillUnmount() {
    this.postBackToMain();
  }

  changeApplicationMonth = (applicationMonth) => {
    let errors = this.state.errors;

    let applicationMonthInt = parseInt(applicationMonth.value, 10) - 1; 
    var startDate = moment(this.state.startDate);
    var currentDate = moment().startOf('month');
    let endDate = startDate.add(applicationMonthInt, 'month').endOf('month');

    if (startDate < currentDate) {
      errors.startDate = ERRORMESSAGE.startDate;
    }
    else {
      errors.startDate = "";
    }

    if(applicationMonthInt >= 1)
      errors.applicationMonth = "";
    this.setState({
      applicationMonth: applicationMonth,
      endDate: moment(endDate).format('YYYY-MM-DD'),
      errors: errors
    });
    this.postBackToMain();
  }

  changeStartDate = (newvalue) => {
    let errors = this.state.errors;

    //selected Start Date must be equal or later than the first day of the current month.
    var startDate = moment(newvalue).startOf('month');
    var endDate = new moment(startDate);
    var currentDate = moment().startOf('month');
    let applicationMonthInt = parseInt(this.state.applicationMonth.value, 10)

    if (startDate < currentDate) {
      errors.startDate = ERRORMESSAGE.startDate;
    }
    else {
      if(applicationMonthInt == 0)
      {
        errors.startDate = "";
      }
      else
      {
        endDate = endDate.add(applicationMonthInt-1, 'month').endOf('month');
        errors.startDate = "";
      }
    }
    this.setState({
      startDate: startDate,
      endDate: endDate,
      errors: errors
    });
    this.postBackToMain();
  }

  handleCellEditCommit = (params, event) => {
    let errors = this.state.errors;  
    const array = this.state.records.map(r => {
      if (r.id === params.id) {
        return { ...r, [params.field]: params.value }
      } else {
        return { ...r }
      }
    });
    
    //Validate IU and Plate
    errors.plateAndIU = "";
    if(this.state.carCount === 2)
    {
      for (const record of array) {
        if(!VALIDEMAILREGEX.test(record.iu) || record.iu.length != 10)
          errors.plateAndIU = ERRORMESSAGE.plateAndIU;
        
        if(record.plate.length === 0)
          errors.plateAndIU = ERRORMESSAGE.plateAndIU;
      }
    }
    else
    {
      if(!VALIDIUREGEX.test(array[0].iu) || array[0].iu.length !== 10)
        errors.plateAndIU = ERRORMESSAGE.plateAndIU;
        
      if(array[0].plate.length === 0)
        errors.plateAndIU = ERRORMESSAGE.plateAndIU;
    }
    this.state.records=array;
    this.setState({
      records: array,
      errors: errors
     });

     this.postBackToMain();
  };

  handleCheckChange = (event) => {
    if (event.target.checked == true) {
      this.setState({ carCount: 2 });
    }
    else {
      this.setState({ carCount: 1 });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // if(validateForm(this.state.errors)) {
    //   console.info('Valid Form')
    // }else{
    //   console.error('Invalid Form')
    // }
  }
  
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'name': 
        errors.name = 
          value.length === 0
            ? ERRORMESSAGE.name
            : "";
        break;
      case 'email':
        let flag=true;
        const emailList=value.split(',');
        for(const element of emailList)
        {
          if(!VALIDEMAILREGEX.test(element))
            flag=false;
        }
        errors.email = 
          flag
            ? ""
            : ERRORMESSAGE.email;
        break;
      case 'phone': 
        errors.phone = 
          value.length < 8
            ? ERRORMESSAGE.phone
            : "";
        break;
      case 'companyName': 
        errors.companyName = 
          value.length ===0
            ? ERRORMESSAGE.company
            : "";
            this.state.companyName=value;
        break;
        case 'iu': 
        errors.iu = 
          (value.length ===0||value.length !=10)
            ? ERRORMESSAGE.iu
            : "";
            this.state.records[0].iu=value;
        break;
        case 'plate': 
        errors.plateAndIU = 
          value.length ===0
            ? ERRORMESSAGE.plate
            : "";
            this.state.records[0].plate=value.toUpperCase();
        break;
      default:
        break;
    }
    if(name!="iu"||name!="plate")
    {    this.setState({errors,[name]: value});}

    this.postBackToMain();
  }

  render() {
    let startDate = moment(this.state.startDate).format('YYYY-MM-DD');
    let endDate = moment(this.state.endDate).format('YYYY-MM-DD');

    let name = this.state.name;
    let email = this.state.email;
    let companyName = this.state.companyName;
    let phone = this.state.phone
    let applicationMonth = this.state.applicationMonth;
    let applicationType = this.state.applicationType;
    let remark = this.state.remark;
    let units = this.state.units;
    let carCount = this.state.carCount;
    let errors = this.state.errors;
    let iu=this.state.records[0].iu;
    let plate=this.state.records[0].plate; 
     if (this.props.isFirstTime === 0&&this.state.ischangedType===0) {
      applicationType=this.props.building==="GWC" ? 'Tenant' : 'Public'; 
      this.state.applicationType=this.props.building==="GWC" ? 'Tenant' : 'Public';
     }
    var rows = null;
    var checked = false;
    if (carCount == 1) {
      rows = this.state.records.slice(0, 1);
    }
    else {
      rows = this.state.records;
      checked = true;
    }

    return (

      <React.Fragment>
        <Form onSubmit={this.handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              disabled="true"
              id="outlined-required"
              label="Building"
              defaultValue=""
              value={this.props.building}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Type</FormLabel>
              <RadioGroup
                row
                aria-label="gender"
                name="controlled-radio-buttons-group"
                value={applicationType} 
                required="true"
                onChange={(event) => {
                  this.setState({ applicationType: event.target.value ,ischangedType:1})
                }}
              >
                   {this.props.building!=="GWC" &&
                <FormControlLabel value="Public" control={<Radio />} label="Public" />
                   }
               <FormControlLabel value="Tenant"  control={<Radio />} label="Tenant" />
               
              </RadioGroup>
            </FormControl>
          </Grid>
          {applicationType == "Tenant" &&
            <Grid item xs={12}>
              <TextField
                id="units"
                name="units"
                label="Unit Number"
                fullWidth
                autoComplete="shipping address-line2"
                value={units}
                onChange={(event) => {
                  this.setState({ units: event.target.value });
                }}
              />
            </Grid>}
          <Grid item xs={12}>
            {/* {
              errors.plateAndIU && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{errors.plateAndIU}</FormText>
            }
            <br /> */}
            {/* <FormGroup>
              <FormControlLabel control={<Checkbox onChange={this.handleCheckChange} checked={checked} />} label="Add Second Car?" />
            </FormGroup> */}
            {/* <div style={{ height: '10%', width: '100%' }}>
              <DataGrid rows={rows} columns={columns} hideFooter='true' autoHeight='true' ref={x=>x} onCellEditCommit={this.handleCellEditCommit}  />
            </div> */}
             <TextField
              id="iu"
              name="iu"
              label="IU"
              fullWidth
              autoComplete="shipping address-line2"
              value={iu}
              onChange={this.handleChange}
              required="true"
              error={errors.iu.length > 0}
              helperText={errors.iu.length > 0 ? errors.iu : ' '}
            />
          </Grid>
          <Grid item xs={12}>
             <TextField
              id="plate"
              name="plate"
              label="Car Plate No."
              fullWidth
              autoComplete="shipping address-line2"
              value={plate}
              onChange={this.handleChange}
              required="true"
              error={errors.plateAndIU.length > 0}
              helperText={errors.plateAndIU.length > 0 ? errors.plateAndIU : ' '}
            />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Month*"
                name="startDate"
                views={['year', 'month']}
                value={startDate}
                onChange={this.changeStartDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <br />
            {
              errors.startDate.length > 0 && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{errors.startDate}</FormText>
            }
          </Grid>

          <Grid item xs={12}>
            <FormText>No. of Month(s)*</FormText>
            <Select
              id="applicationMonth"
              name="applicationMonth"
              label="No. of Month(s) to Apply"
              fullWidth
              options={noMonthRenewOptions}
              value={applicationMonth}
              onChange={this.changeApplicationMonth}
              required
            />
            <br />
            {
              errors.applicationMonth.length > 0 && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{errors.applicationMonth}</FormText>
            }
          </Grid>
          
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled
                label="End Month*"
                value={endDate}
                views={['year', 'month']}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="name"
              name="name"
              label="Name"
              fullWidth
              autoComplete="shipping address-line2"
              value={name}
              onChange={this.handleChange}
              required="true"
              error={errors.name.length > 0}
              helperText={errors.name.length > 0 ? errors.name : ' '}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              name="email"
              label="Email"
              fullWidth
              autoComplete="shipping address-line2"
              
              value={email}
              onChangeCapture={this.handleChange}
              required
              error={errors.email.length > 0}
              helperText={errors.email.length > 0 ? errors.email : ' '}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="phone"
              name="phone"
              label="Phone"
              fullWidth
              autoComplete="shipping address-line2"
              value={phone}
              onChange={this.handleChange}
              required
              error={errors.phone.length > 0}
              helperText={errors.phone.length > 0 ? errors.phone : ' '}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="companyName"
              name="companyName"
              label="Company Name"
              fullWidth 
              autoComplete="shipping address-line2"
              value={companyName}
              onChange={this.handleChange}
              required
              error={errors.companyName.length > 0}
              helperText={errors.companyName.length > 0 ? errors.companyName : ' '}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="remark"
              name="remark"
              label="Remark"
              fullWidth
              autoComplete="shipping address-line2"
              value={remark}
              onChange={(event) => {
                this.setState({ remark: event.target.value });
              }}
            />
          </Grid>
          <FormText style={{ color: 'rgb(9, 143, 175)' }}> <p>* By submitting this application, you consent to the collection, use 
and disclosure of your personal data, and if applicable, that you 
have been duly authorised by the individual that you are 
submitting this application on behalf of, to give his/her consent 
for the collection, use or disclosure of his/her personal data.</p>
            </FormText>
        </Grid>
        </Form>
      </React.Fragment>
    )
  }
}
