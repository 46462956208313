import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Select from 'react-select';
import { DataGrid } from "@mui/x-data-grid";
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Form, FormText } from "react-bootstrap";

const TerminationReasons = [
  { label: "Other", value: 0 },
  { label: "Vehicle Sold", value: 1 },
  { label: "Vehicle Scrapped", value: 2 },
  { label: "Vehicle Return To Company", value: 3 },
  { label: "Change Of Address", value: 4 }
];

const columns = [
  { field: 'iu', headerName: 'IU', width: 150, editable: false },
  { field: 'plate', headerName: 'Plate', width: 150, editable: false }
]

export class TerminationDetail extends Component {
  constructor(pros) {
    super(pros);
    this.state = {
      terminationReason: -1,
      refundAmount : 0,
      terminationDate: "",
      payeeName:"",
      remark:"",
      errors:{
        terminationDate: "",
        terminationReason: "",
        payeeName:"Payee Name is not valid!",
        remark:""
      }
    };
  }

  componentDidMount() {
    if(this.props.isFirstTime == 1)
    {
      this.setState({terminationReason : this.props.terminationReason,
                     terminationDate : this.props.terminationDate,
                     refundAmount : this.props.refundAmount});
    }
  }

  postBackToMain(){
    let childData = {
      terminationReason: this.state.terminationReason,
      terminationDate : this.state.terminationDate,
      refundAmount : this.state.refundAmount,
      payeeName:this.state.payeeName,
      remark:this.state.remark,
      isFirstTime : 1,
      errors: this.state.errors
    };
    this.props.parentCallback(childData);
  }

  componentWillUnmount(){
    this.postBackToMain();
  }
  
  handleTerminationTypeChange = (terminationReason) =>{
    let errors = this.state.errors;
    errors.terminationReason = "";
    this.setState({
      terminationReason: terminationReason,
      errors: errors
    });
    this.postBackToMain();
  }
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'payeeName': 
        errors.payeeName = 
          value.length === 0
            ? "Payee Name is not valid!"
            : "";
        break; 
        case 'remark': 
        errors.remark = 
          value.length === 0
            ? "Remark is not valid!"
            : "";
        break; 
      default:
        break;
    } 
    this.setState({errors,[name]: value});
    this.postBackToMain();
  }
  changeTerminationDate = (newvalue)=> {
    let errors = this.state.errors;

    var terminationDate = moment(newvalue).startOf('month');
    var terminationDateString = terminationDate.format('YYYY-MM-DD');
    var currentDate = moment(new Date()).startOf('month').format('YYYY-MM-DD'); 
    let startdate = moment(this.props.startDate);
    let enddate = moment(this.props.endDate);

    errors.terminationDate = "";
    if(terminationDate < startdate || terminationDate > enddate)
    {
      errors.terminationDate = "Invalid Termination Date, please select a date between current start date and end date."
    }
  if(terminationDateString===currentDate)
  {
    errors.terminationDate = "Earliest termination is from the next month."
  }
  if(terminationDate<moment(new Date()).startOf('month'))
  {
    errors.terminationDate = "Earliest termination is from the next month."
  }
    const postData = {
      building: this.props.building,
      startdate: terminationDateString,
      seasonId: this.props.seasonId
    };

    fetch(process.env.REACT_APP_API + 'GetRefundAmount', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then(data => data.json())
      .then((data) => {
        console.log(data.Fee)
        this.setState({
          refundAmount: parseFloat(data.Fee).toFixed(2)/100,
          terminationDate: terminationDateString,
          errors: errors
        });
      });
    this.postBackToMain();
  }

  render() {
    let startDate = moment(this.props.startDate).format('YYYY-MM-DD');
    let endDate = moment(this.props.endDate).format('YYYY-MM-DD');
    let terminationDate = this.state.terminationDate;
    let refundAmount = this.state.refundAmount;
    let terminationReason = this.state.terminationReason;
    let errors = this.state.errors;
    let payeeName=this.state.payeeName;
    let remark=this.state.remark;
    const rows = this.props.records;
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              disabled="true"
              id="outlined-required"
              label="Season ID"
              defaultValue=""
              value={this.props.seasonId}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: '10%', width: '100%' }}>
              <DataGrid 
                disabled='true' 
                rows={rows}
                getRowId={(r) => r.iu} 
                columns={columns} 
                hideFooter='true' 
                autoHeight='true' />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled="true"
              id="seasonParkingStartDate"
              name="seasonParkingStartDate"
              label="Current Season Parking Start Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={startDate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled="true"
              id="seasonParkingEndDate"
              name="seasonParkingEndDate"
              label="Current Season Parking End Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={endDate}
            />
          </Grid>
          
          <Grid item xs={12}>
            <Form.Group size="lg" controlId="Building">
                      <FormText style={{ color: 'black' }}>Termination Reason *</FormText>
                      <Select
                        id="TerminationReason"
                        fullWidth
                        name="TerminationReason"
                        options={TerminationReasons}
                        label="Termination Reason"
                        value={terminationReason}
                        onChange={this.handleTerminationTypeChange}
                      >
                      </Select>
            </Form.Group>
            {
                errors.terminationReason.length > 0 && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{errors.terminationReason}</FormText>
            }
          </Grid>

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Termination Month*"
                views={['year', 'month','day']}
                value={terminationDate}
                onChange={this.changeTerminationDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <br/>
            {
              errors.terminationDate.length > 0 && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{errors.terminationDate}</FormText>
            }
          </Grid>

          <Grid item xs={12}>
            <TextField
              disabled
              id="refundAmount"
              name="refundAmount"
              label="Refund Amount"
              fullWidth
              autoComplete="shipping address-line2"
              value={refundAmount}
            />
          </Grid>
          {terminationReason.value===0 &&
             <Grid item xs={12}>
             <TextField
               id="remark"
               name="remark"
               label="Remark"
               fullWidth
               autoComplete="shipping address-line2"
               value={remark}
               onChange={this.handleChange}
               error={errors.remark.length > 0}
               helperText={errors.remark.length > 0 ? errors.remark : ' '}
             />
           </Grid>
          } 
          <Grid item xs={12}>
            <TextField
              id="payeeName"
              name="payeeName"
              label="Refund Payee Full Name"
              fullWidth
              autoComplete="shipping address-line2"
              value={payeeName}
              onChange={this.handleChange}
              error={errors.payeeName.length > 0}
              helperText={errors.payeeName.length > 0 ? errors.payeeName : ' '}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}
