import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { SecondApplicationDetail } from './SecondApplicationDetail';
import { SecondApplicationReview } from './SecondApplicationReview';
import { withStyles } from '@material-ui/core/styles';
import { Route, withRouter } from 'react-router-dom';
import { Navigation } from '../../Navigation';
import moment from 'moment';
import { HideFooter } from '../../HideFooter.js';
import { COPYRIGHT_BUSINESSENTITIES, ERRORMESSAGE, RECAPTCHA_ACTIONS } from '../../Constants.js';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://shop.greatworld.com.sg/">
      {new Date().getFullYear()} {COPYRIGHT_BUSINESSENTITIES}
      </Link>{' '}
      
      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    align: 'right'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

const steps = ['Details', 'Review your order'];

const validateForm = errors => {
  let valid = true;
  
  for (const [key, value] of Object.entries(errors)) {
    if(value.length > 0)
      valid = false;
  }
  
  return valid;
};

class SeasonParkingSecondApplication extends Component {
  constructor(pros) {
    super(pros);
    this.state = {
      activeStep: 0,

      building: "",
      seasonid: "",
      startDate: "",
      endDate: "",
      seasontype: 0,
      name: "",
      phone: "",
      email: "",
      companyName: "",
      carCount: 0,
      categoryid: "",
      plate: "",
      iu: "",
      records: [],
      secondRecord: [],
      reCaptchaToken: "",
      disabled: false,
      modalIsOpen:false,
      modal2IsOpen:false,
      errors: {
        plateAndIU: "Field is required",
        iu:""
      }
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    const postData = { building: this.props.location.state.building, plate: this.props.location.state.plate };

    fetch(process.env.REACT_APP_API + 'GetSeasonParkingInfo', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then(data => data.json())
      .then((data) => {
        var carCount = Object.keys(data.records).length;
        var rows = [{id:1, iu:'', plate:''}]
        rows[0].iu = data.records[0].iu;
        rows[0].plate = data.records[0].plate;
        this.setState({
          building: postData.building,
          seasonid: data.seasonid,
          startDate: data.startdate,
          endDate: data.enddate,
          seasontype: data.seasontype,
          name: data.name,
          phone: data.phone,
          email: data.email,
          companyName: data.companyname,
          carCount: carCount,
          categoryid: data.categoryid,
          records: rows
        });
      })
  }

  handleNext() {
    
    //Validate field before proceed to Review page
    let errors = this.state.errors;
    errors.plateAndIU="";
    errors.iu="";
    if( validateForm(errors) == false )
      {
       this.setState({
         errors: errors
       }); 
       return;
      }

    if(this.state.disabled)
      return;

    //Create Season Parking Renewal
    if (this.state.activeStep == 1) {
      this.setState({ disabled: true })
      let startDate = moment(this.state.startDate).format('YYYY-MM-DD');
      let endDate = moment(this.state.endDate).format('YYYY-MM-DD');
      //continue after getting recaptcha token
      new Promise(function(resolve,reject){
        try{
          window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: RECAPTCHA_ACTIONS.SeasonParkingSecondApplication })
          .then(token => {
            resolve(token);
          })
        }catch(error){
          reject(error);
        }
      }).catch(() => {
        alert(ERRORMESSAGE.recaptcha);
        this.setState({                
          disabled: false
        })
      }).then((value)=>{
      this.setState({reCaptchaToken : value});
      const applicationData = {
          building : this.state.building,
          seasonid: this.state.seasonid,
          categoryID: this.state.categoryid,
          startDate :  startDate,
          endDate : endDate,
          recaptchaToken : this.state.reCaptchaToken,
          name : this.state.name,
          phone : this.state.phone,
          email : this.state.email,
          companyName : this.state.companyName,
        
          isSecond : 1,
          records : this.state.secondRecord,
          oldRecords: this.state.records
        };
      
        fetch(process.env.REACT_APP_API + 'CreateSeasonParking', {
          method: 'POST',
          headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
          },
          body: JSON.stringify(applicationData)
        })
        .then(data => data.json())
        .then((data) =>{ 
          if (data.result === "ok") {
            this.setState({
              activeStep: this.state.activeStep + 1,
              disabled: false
            });
          } else {
          //Result is fail  
          alert(data.Message)
          this.state.activeStep = 0;
          this.setState({
           disabled: false
         });
          } 
        },
        (error) => {
          this.setState({
            errors: 'Hit error in API call'
          });
        })
      })
    }else{
      this.setState({disabled: true});
      const postData = { 
        building: this.state.building,
        plate: this.state.secondRecord[0].plate,
        iu:this.state.secondRecord[0].iu
      };
      fetch(process.env.REACT_APP_API + 'LoginIu', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
      })
        .then(data => data.json())
        .then(
          (data) => {
            //Result is ok
            if (data.result === "ok"&&data.iuplatevalid==="1") {
              this.setState({
                activeStep: this.state.activeStep + 1
              });
            } else {
              //Result is fail 
              let errors = this.state.errors; 
              errors.plateAndIU= data.reason;
              errors.iu= data.reason;
              this.setState({
                errors:errors
              });
            }
            this.setState({disabled:false}); 
          },
          (error) => {
            this.setState({
              errors: 'Hit error in API call'
            });
          }) 
    }
  }

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    });
  }

  callbackDetail = (childData) => {
    this.setState({
      secondRecord: childData.secondRecord,
      isFirstTime : childData.isFirstTime,
      errors: childData.errors
    });
  }

  getStepContent(step, callbackDetail) {
    switch (step) {
      case 0:
        return <SecondApplicationDetail
          seasonid={this.state.seasonid}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          building={this.state.building}
          carCount={this.state.carCount}
          categoryid={this.state.categoryid}
          records={this.state.records}
          secondRecord={this.state.secondRecord}
          isFirstTime = {this.state.isFirstTime}
          errors = {this.state.errors}
          parentCallback={callbackDetail} />;
      case 1:
        return <SecondApplicationReview
          seasonid={this.state.seasonid}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          building={this.state.building}
          records={this.state.records}
          secondRecord={this.state.secondRecord}
          carCount={this.state.carCount}
          categoryid={this.state.categoryid}
          name={this.state.name}
          phone={this.state.phone}
          email={this.state.email}
          companyName={this.state.companyName} />;
      default:
        throw new Error('Unknown step');
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Navigation />
        <CssBaseline />
        <AppBar position="absolute" color="default" className={classes.appBar}>
          {/* <Toolbar>
            <Typography variant="h6" color="inherit" noWrap>
              Season Parking Extension
            </Typography>
          </Toolbar> */}
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Season Parking Application (for 2nd Vehicle)
            </Typography>
            <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {this.state.activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your application.
                  </Typography>
                  <Typography variant="subtitle1">
                  Your season parking application for a second car has been submitted for approval.  Once it has been approved, you will receive a confirmation email with payment details. Please note that payment can only be made through a QR code which will be embedded in the confirmation email. This QR code will only be valid for 48 hours*.
                  </Typography>
                  <br/>
                  <Typography variant="subtitle1">
                    For further query, please contact it.support@allgreen.com.sg.
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.getStepContent(this.state.activeStep, this.callbackDetail)}

                  <div className={classes.buttons}>

                    {this.state.activeStep !== 0 && (
                      <Button onClick={this.handleBack} className={classes.button} disabled={this.state.disabled}>
                        Back
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      align="right"
                      disabled={this.state.disabled}
                      onClick={this.handleNext}
                      className={classes.button}
                    >
                      {this.state.activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                    </Button>

                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </main>
        <Copyright />
       <HideFooter />
      </React.Fragment>
    )
  }
}

{/* <Route component={SeasonParkingExtension} />; */ }

export default withRouter(withStyles(styles)(SeasonParkingSecondApplication))
