import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { UpdateDetail } from './UpdateDetail';
import { UpdateReview } from './UpdateReview';
import { withStyles } from '@material-ui/core/styles';
import {  withRouter } from 'react-router-dom';
import { Navigation } from '../../Navigation';
import {  FormText } from "react-bootstrap";
import { HideFooter } from '../../HideFooter.js';
import { COPYRIGHT_BUSINESSENTITIES, ERRORMESSAGE, RECAPTCHA_ACTIONS } from '../../Constants.js';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://shop.greatworld.com.sg/">
      {new Date().getFullYear()} {COPYRIGHT_BUSINESSENTITIES}
      </Link>{' '}
      
      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    align: 'right'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});
const validateForm = errors => {
  let valid = true;
  for (const [key, value] of Object.entries(errors)) {
    if(value.length > 0)
      valid = false;
  }
  return valid;
};
const steps = ['Details', 'Review your order'];

class SeasonParkingUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      
      building: "",
      seasonId: "",
      startDate: "",
      endDate: "",
      seasontype: 0,
      name: "",
      phone: "",
      email: "",
      companyName: "",
      carCount: 0,
      categoryid: "",
      records: [],
      reCaptchaToken : "",
      changeType : "tempcar",
      changeStartDate : "",
      changeEndDate : "",
      changeMonth: 0,
      changeRecords: [],
      errorCode: -1,
      errors:{
        noOfChange: "",
        plateAndIU: "Field is required",
        iu:"Field is required" ,
        startDate:"",
        enddate:""
      },
      disabled: false
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    const postData = { building: this.props.location.state.building, 
                       plate: this.props.location.state.plate,
                       transactionType: 5 };

    fetch(process.env.REACT_APP_API + 'GetSeasonParkingInfo', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then(data => data.json())
      .then((data) => {
        var carCount = Object.keys(data.records).length;
        var rows = [{id:1, iu:'Test', plate:'Test'}]
        rows[0].iu = data.records[0].iu;
        rows[0].plate = data.records[0].plate;
        // var rows = [];
        // data.records.map(r => {
        //   rows.push([{id : 1, iu : r.iu, plate: r.plate}])
        // })
        this.setState({
          building: postData.building,
          seasonId: data.seasonid,
          startDate: data.startdate,
          endDate: data.enddate,
          seasontype: data.seasontype,
          name: data.name,
          phone: data.phone,
          email: data.email,
          companyName: data.companyname,
          carCount: carCount,
          categoryid: data.categoryid,
          records: rows
        });
      })
  }

  handleNext() {
  let error=this.state.errors;
  if(this.state.changeType!=="tempcar"&&error.enddate.length>0)
  error.enddate="";
  if(this.state.changeEndDate.length>0)
  error.enddate="";
  if(this.state.changeStartDate.length>0)
  error.startDate="";
  if( validateForm(error) == false )
  {
   this.setState({
     errors: error,
     errorCode:1
   }); 
   return;
  }
    if(this.state.disabled)
      return;

    //Create Season Parking Update
    if (this.state.activeStep == 1) {
      //Control Next/Back buttun
      this.setState({ disabled: true })
      //continue after getting recaptcha token
      new Promise(function(resolve,reject){
        try{
          window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: RECAPTCHA_ACTIONS.SeasonParkingUpdate }).then(token => {
            resolve(token);
          })
        }catch(error){
          reject(error);
        }
      }).catch(error => {
        alert(ERRORMESSAGE.recaptcha);
        this.setState({                
          disabled: false
        })
      }).then((value)=>{
        this.setState({reCaptchaToken : value});
        const updateData = {
          building: this.state.building,
          seasonId: this.state.seasonId,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          records: this.state.changeRecords,
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          companyName: this.state.companyName,
          recaptchaToken: this.state.reCaptchaToken,
          changeType: this.state.changeType,
          tempStartDate: this.state.changeStartDate,
          tempEndDate: this.state.changeEndDate,
          effectiveDate: this.state.changeStartDate,
          oldRecords: this.state.records
        };
      
        fetch(process.env.REACT_APP_API + 'UpdateParking', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updateData)
        })
        .then(data => data.json())
        .then((data) => {
          if (data.result === "ok") {
            this.setState({
              activeStep: this.state.activeStep + 1,
              disabled: false
            });
          } else {
            //Result is fail  
            alert(data.Message)
            this.state.activeStep = 0;
            this.setState({
              disabled: false
            });
          } 
        })
        //
      })
    }else{

      //Validation before review/submit
      let errors = this.state.errors;

      const updateData = {
        building: this.state.building,
        seasonId: this.state.seasonId,
        changeType: this.state.changeType,
        oldRecords: this.state.records
      };

      let response = fetch(process.env.REACT_APP_API + 'UpdateNoOfChangeCheck', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateData)
      })
      .then(data => data.json())
      .then((data) =>{
        console.log(data.result);
        if(data.result == "ok")
        {
          this.setState({
            activeStep: this.state.activeStep + 1
          });
        }
        else
        {
          console.log("Current Num Of Change is: " + data.numOfChange);
          errors.noOfChange = "Change of IU/Plate has reached maxminum times.";
          this.setState({
            errors: errors
          })
        }
      })
    }
  }

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    });
  }

  callbackDetail = (childData) => {
    this.setState({
      changeType : childData.changeType,
      changeStartDate : childData.changeStartDate,
      changeEndDate : childData.changeEndDate,
      changeMonth : childData.changeMonth,
      changeRecords : childData.changeRecords,
      isFirstTime : childData.isFirstTime,
      errors:childData.errors
    });
  }

  getStepContent(step, callbackDetail) {
    switch (step) {
      case 0:
        return <UpdateDetail
          seasonId={this.state.seasonId}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          building={this.state.building}
          carCount={this.state.carCount}
          categoryid={this.state.categoryid}
          records={this.state.records}
          changeType={this.state.changeType}
          changeStartDate={this.state.changeStartDate}
          changeEndDate={this.state.changeEndDate}
          changeMonth={this.state.changeMonth}
          changeRecords={this.state.changeRecords}
          isFirstTime = {this.state.isFirstTime}
          errors = {this.state.errors}
          parentCallback={callbackDetail} />;
      case 1:
        return <UpdateReview
          seasonId={this.state.seasonId}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          building={this.state.building}
          records={this.state.records}
          carCount={this.state.carCount}
          categoryid={this.state.categoryid}
          name={this.state.name}
          phone={this.state.phone}
          email={this.state.email}
          companyName={this.state.companyName}
          changeType={this.state.changeType}
          changeStartDate={this.state.changeStartDate}
          changeEndDate={this.state.changeEndDate}
          changeMonth={this.state.changeMonth}
          errors = {this.state.errors}
          changeRecords={this.state.changeRecords} />;
      default:
        throw new Error('Unknown step');
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Navigation />
        <CssBaseline />
        <AppBar position="absolute" color="default" className={classes.appBar}>
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Change of IU / Plate Number
            </Typography>
            {
              this.state.errors.noOfChange.length > 0 && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{this.state.errors.noOfChange}</FormText>
            }
            <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {this.state.activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                  Your change request has been submitted.
                  </Typography>
                  <Typography variant="subtitle1">
                  You will be receiving a confirmation email in your inbox that requires further action from you. Once you have confirm the change request through your email, your request will be processed from the selected month.
                  </Typography>
                  <br/>
                  <Typography variant="subtitle1">
                    For further query, please contact it.support@allgreen.com.sg.
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.getStepContent(this.state.activeStep, this.callbackDetail)}

                  <div className={classes.buttons}>

                    {this.state.activeStep !== 0 && (
                      <Button onClick={this.handleBack} className={classes.button} disabled={this.state.disabled}>
                        Back
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      align="right"
                      disabled={this.state.disabled}
                      onClick={this.handleNext}
                      className={classes.button}
                    >
                      {this.state.activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                    </Button>

                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </main>
        <Copyright />
        <HideFooter />
      </React.Fragment>
    )
  }
}

{/* <Route component={SeasonParkingExtension} />; */ }

export default withRouter(withStyles(styles)(SeasonParkingUpdate))
