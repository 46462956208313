import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import {ListItem,List} from '@material-ui/core';
import Select from 'react-select';
import {FormText} from "react-bootstrap";
import {MAX_RENEWAL_MONTHS,ERRORMESSAGE} from '../../Constants.js'

let extensionMonths=[];
let noMonthRenewOptions=[];
export class ExtensionDetail extends Component {
  constructor(pros) {
    super(pros);
    this.state = {
      seasonID: "",
      startdate: "",
      enddate: "",
      RenewalStartdate:"",
      extensionMonth: 0,
      newEndDate: "",
      skipMonths:"",
      fee: 0,
      records:this.props.records,
      errors: {
        startDate: ""
      }
    };
    this.calculateCharge = this.calculateCharge.bind(this);
  }
  componentDidMount() {

    if (this.props.extensionMonth != 0)
      this.setState({ extensionMonth: this.props.extensionMonth });
    if (this.props.fee != 0)
      this.setState({ fee: this.props.fee });
    if (this.props.newEndDate != "")
    {
      this.setState({ newEndDate: this.props.newEndDate });
    }
  }

  changeExtensionMonth = (extensionMonth)=> {
    let extensionMonthInt = parseInt(extensionMonth.value, 10);

    let startdate = new Date(this.props.startdate.split('T')[0]);
    let enddate = new Date(this.props.enddate.split('T')[0]);
    var newStartDate = moment(this.state.RenewalStartdate);
    let dt = newStartDate.add(extensionMonthInt-1, 'month').endOf('month');
    this.setState({
      extensionMonth: extensionMonth,
      newEndDate: moment(dt).format('YYYY-MM-DD'),
      startdate: startdate,
      enddate: enddate,
      RenewalStartdate: moment(this.state.RenewalStartdate).format('YYYY-MM-DD'),
    });
    this.calculateCharge(moment(this.state.RenewalStartdate).format('YYYY-MM-DD'),moment(dt).format('YYYY-MM-DD'));
  }
  //calculate  Month skip enddate to new renewal start date 
  calculateSkipMonth=(startDate)=>{
    let newstartdate =  moment(startDate.value);
    let enddate = moment(this.props.enddate.split('T')[0]).startOf('month');
    let diffMonth = moment(newstartdate).diff(enddate,'month');
    var skipDate="";
    for(let i =1;i<diffMonth;i++)
    {
      skipDate+=moment(newstartdate).add(-i,'month').format('MMM-YYYY')+",";
    }

    // Flush the options 
    noMonthRenewOptions=[];
    // Populate options based on new starting month
    for(let i = 1; i <= MAX_RENEWAL_MONTHS - diffMonth + 1 ; ++i)
    {
      let option={label:i,value:i}
      noMonthRenewOptions.push(option)
    }
    skipDate=skipDate.substring(0,skipDate.length-1);
    this.setState({skipMonths:skipDate,RenewalStartdate:new Date(newstartdate)});
    this.state.RenewalStartdate=new Date(newstartdate);
    this.state.extensionMonth = {label:1, value: 1}
    if(this.state.extensionMonth.value>0)
         this.changeExtensionMonth(this.state.extensionMonth)
  }
  calculateCharge(newStartDate,newEndDate) {
    const postData = {
      building: this.props.building,
      categoryid: this.props.categoryid,
      startdate: newStartDate,
      enddate: newEndDate,
      carcount: 1,
      seasonid: this.props.seasonid
      // plate: "",
      // iu: this.props.iu,
    };
    let errors = this.state.errors;
    errors.startDate="";
    if(moment(postData.startdate).month -moment(this.state.enddate).month> MAX_RENEWAL_MONTHS)
    {
      errors.startDate=ERRORMESSAGE.RenwalStarDate;
      this.setState({errors:errors});
      return;
    }
    fetch(process.env.REACT_APP_API + 'GetCategoryRate', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then(data => data.json())
      .then((data) => {
        this.setState({
          fee: parseFloat(data.Fee).toFixed(2)/100
        });
        let childData = {
          fee: parseFloat(data.Fee).toFixed(2)/100,
          newEndDate: this.state.newEndDate,
          extensionMonth: this.state.extensionMonth,
          RenewalStartdate:this.state.RenewalStartdate,
          skipMonths:this.state.skipMonths
        };
        this.props.parentCallback(childData);
      });
  }

  render() {
    let startdate = moment(this.props.startdate).format('YYYY-MM-DD');
    let enddate = moment(this.props.enddate).format('YYYY-MM-DD');
    
    let btCalculateStatus = true;
    if (this.state.extensionMonth.value != 0) {
      btCalculateStatus = false;
    }
    //Flush extensionMonths options
    extensionMonths = []
    //To populate the next 3 months from 
    //the Existing Season Parking End Date
    if(this.props.enddate !=="")
    {
        for(let i =1;i<=MAX_RENEWAL_MONTHS;i++)
        {
          var startMonth =moment(this.props.enddate.split('T')[0]).startOf('month').add(i,'month').format('YYYY-MM-DD');
          let item={label:moment(startMonth).format('MMM-YYYY'),value:startMonth}
          extensionMonths.push(item);
        }
    }
    const records = this.props.records;
    const columns = [{field:'Plate',width:150}, {field:'IU', width:150}];
    const {extensionMonth} = this.state;
    const {extensionStartDate}=this.state.RenewalStartdate;
    //const records = [{ "plate": "plate 1", "iu": "iu 1" }, { "plate": "plate 2", "iu": "iu 2" }];
    return (
      <React.Fragment>
        {/* <Typography variant="h6" gutterBottom>
          Extend Season Parking
        </Typography> */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              disabled="true"
              id="outlined-required"
              label="Season ID"
              defaultValue=""
              value={this.props.seasonid}
            />
          </Grid>
          <Grid item xs={12}>
            <div>{
              <List>
              {records.map((car) => {
                return ( 
                    <ListItem style={{fontWeight: 'bold'}}>
                      Plate No : {car.plate} 
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      IU : {car.iu}
                    </ListItem>

                  // <div key={car.plate}>
                  //   <div className="card bwm-card">
                  //     <div>
                  //       <h6 className="card-subtitle mb-0 text-muted">
                  //         plate : {car.plate}             iu : {car.iu}
                  //       </h6>
                  //     </div>
                  //   </div>
                  // </div>
                )
              })}
              </List>
            }
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled="true"
              id="seasonParkingStartDate"
              name="seasonParkingStartDate"
              label="Previous Season Parking Start Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={startdate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled="true"
              id="seasonParkingEndDate"
              name="seasonParkingEndDate"
              label="Previous Season Parking End Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={enddate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled="true"
              id="seasonParkingskipMonths"
              name="seasonParkingskipMonths"
              label="Skip Months"
              fullWidth
              display={this.state.skipMonths.length>0}
              autoComplete="shipping address-line2"
              value={this.state.skipMonths}
            />
          </Grid>
          <Grid item xs={12}>
          <FormText>New Season Parking Start Date*</FormText>
            <Select
              id="newSeasonParkingStartDate"
              name="newSeasonParkingStartDate"
              label="New Season Parking Start Date"
              fullWidth
              required="true"
              options={extensionMonths}
              value={extensionStartDate}
              onChange={this.calculateSkipMonth}
              error={this.state.errors.startDate.length > 0}
              helperText={this.state.errors.startDate.length  > 0 ? this.state.errors.startDate  : ''}
            />
            </Grid>
            <Grid item xs={12}>
            <FormText>No. of Month(s) to Renew *</FormText>
            <Select
              id="extendMonth"
              name="extendMonth"
              label="No. of Month(s) to Extend"
              fullWidth
              //autoComplete="shipping address-line2"
              defaultValue={{label:1,value:1}}
              options={noMonthRenewOptions}
              value={extensionMonth}
              onChange={this.changeExtensionMonth}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="newSeasonParkingEndDate"
              name="newSeasonParkingEndDate"
              label="New Season Parking End Date"
              fullWidth
              autoComplete="shipping address-line2"
              value={this.state.newEndDate}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Button variant="contained" color="primary" align="right" onClick={this.calculateCharge} disabled={btCalculateStatus}>Calculate</Button>
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              disabled
              id="totalChargeToPay"
              name="totalChargeToPay"
              label="Total Charge Payable"
              fullWidth
              autoComplete="shipping address-line2"
              value={this.state.fee}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}
