import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { ExtensionDetail } from './ExtensionDetail';
import { ExtensionReview } from './ExtensionReview';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Navigation } from '../../Navigation';
import { HideFooter } from '../../HideFooter.js';
import {STEPS,ERRORMESSAGE, RECAPTCHA_ACTIONS, COPYRIGHT_BUSINESSENTITIES} from '../../Constants.js'
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://shop.greatworld.com.sg/">
      {new Date().getFullYear()} {COPYRIGHT_BUSINESSENTITIES}
      </Link>{' '}
      
      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    align: 'right'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

class SeasonParkingExtension extends Component {
  constructor(pros) {
    super(pros);
    this.state = {
      activeStep: 0,

      building: "",
      seasonid: "",
      startdate: "",
      enddate: "",
      RenewalStartdate:"",
      extensionMonth: 0,
      newEndDate: "",
      skipMonths:"",
      seasontype: 0,
      name: "",
      phone: "",
      email: "",
      companyname: "",
      carCount: 0,
      categoryid: "",
      plate: "",
      iu: "",
      fee: 0,
      reCaptchaToken: "",
      records: [{
        plate: "",
        iu: ""
      }],
      QRCode: [],
      disabled: false,
      objectNumber:""
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    const postData = { building: this.props.location.state.building, plate: this.props.location.state.plate };

    fetch(process.env.REACT_APP_API + 'GetSeasonParkingInfo', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then(data => data.json())
      .then((data) => {
        var carCount = Object.keys(data.records).length;
        this.setState({
          building: postData.building,
          seasonid: data.seasonid,
          startdate: data.startdate,
          enddate: data.enddate,
          seasontype: data.seasontype,
          name: data.name,
          phone: data.phone,
          email: data.email,
          companyname: data.companyname,
          carCount: carCount,
          categoryid: data.categoryid,
          records: data.records
        });
      })
    //Checked record is paid

      this.dataPolling = setInterval(
        () => {
          if(this.state.activeStep === STEPS.length&&this.state.objectNumber!=="")
          {
          this.getAITraining();
          }
        },
        3000);
  }
  componentWillUnmount() {
    clearInterval(this.dataPolling);
  }
  getAITraining=()=>{
    const postData = { ObjectNumber: this.state.objectNumber,seasonid:this.state.seasonid};
    fetch(process.env.REACT_APP_API + 'CheckRenewalPaid', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then(data => data.json())
      .then((data) => {
        if(data===true)
        {
          this.props.history.push({
            pathname: "/Container/Pay/Home"         
          })
        }
      },(error)=>{this.setState({
        errors: 'Hit error in API call'
      });})
  }
  handleNext() {
     if(this.state.newEndDate===""||this.state.fee===0)
       {
        alert(ERRORMESSAGE.selectRenewalDate,ERRORMESSAGE.selectRenewalDate) 
        return;}
    //Create Season Parking Renewal
    //Also get return QR code to show to customer
    if (this.state.activeStep == 1) {
      this.setState({ disabled: true })
      //continue after getting recaptcha token
      new Promise(function(resolve,reject){
        try{
          window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: RECAPTCHA_ACTIONS.SeasonParkingRenewal }).then(token => {
            
            resolve(token);
          })
        }catch(error){
          reject(error);
        }
      }).catch(() => {
        alert(ERRORMESSAGE.recaptcha);
        this.setState({                
          disabled: false
        })
      }).then((value)=>{
        this.setState({reCaptchaToken : value});
        const renewalData = {
          building: this.state.building,
          seasonid: this.state.seasonid,
          startdate: this.state.startdate,
          enddate: this.state.enddate,
          RenewalStartdate:this.state.RenewalStartdate,
          Renewaldate: this.state.newEndDate,
          skipMonths:this.state.skipMonths,
          seasontype: 0,
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          companyname: this.state.companyname,
          records: this.state.records,
          grouplimit: 0,
          payment: this.state.fee,
          indicator: true,
          recaptchaToken: this.state.reCaptchaToken,
        };
        fetch(process.env.REACT_APP_API + 'CreateSeasonParkingRenewal', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(renewalData)
        })
        .then(data => data.json())
        .then((data) => {
          if (data.result === "ok") {
            this.setState({
              QRCode: data.QR,
              objectNumber:data.RenwalNumber
            } , () => {
              this.setState({
                activeStep: this.state.activeStep + 1,
                disabled: false
              });
            })
          } else {
            alert(data.Message)
            this.state.activeStep = 0;
            this.setState({
              disabled: false
            });
          }
        })
      })
    }else{
      this.setState({
        activeStep: this.state.activeStep + 1
      });
    }
  }

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    });
  }

  callbackDetail = (childData) => {
    this.setState({
      fee: childData.fee,
      newEndDate: childData.newEndDate,
      extensionMonth: childData.extensionMonth,
      RenewalStartdate:childData.RenewalStartdate,
      skipMonths:childData.skipMonths
    });
    console.log(childData.fee);
  }

  getStepContent(step, callbackDetail, fee) {
    console.log(this.state.records);
    switch (step) {
      case 0:
        return <ExtensionDetail
          seasonid={this.state.seasonid}
          startdate={this.state.startdate}
          enddate={this.state.enddate}
          RenewalStartdate={this.state.RenewalStartdate}
          skipMonths={this.state.skipMonths}
          extensionMonth={this.state.extensionMonth}
          newEndDate={this.state.newEndDate}
          building={this.state.building}
          carCount={this.state.carCount}
          categoryid={this.state.categoryid}
          fee={this.state.fee}
          records={this.state.records}
          parentCallback={callbackDetail} />;
      case 1:
        return <ExtensionReview
          fee={fee}
          seasonid={this.state.seasonid}
          startdate={this.state.startdate}
          enddate={this.state.enddate}
          RenewalStartdate={this.state.RenewalStartdate}
          skipMonths={this.state.skipMonths}
          extensionMonth={this.state.extensionMonth}
          newEndDate={this.state.newEndDate}
          building={this.state.building}
          carCount={this.state.carCount}
          categoryid={this.state.categoryid}
          name={this.state.name}
          phone={this.state.phone}
          email={this.state.email}
          companyname={this.state.companyname} />;
      default:
        throw new Error('Unknown step');
    }
  }

  render() {
    const { classes } = this.props;
    //const QRCode = this.state.QRCode;
    const QRCode = "data:image/jpg;base64," + this.state.QRCode;
      return (
        <React.Fragment>
          <Navigation />
          <CssBaseline />
          <AppBar position="absolute" color="default" className={classes.appBar}>
            {/* <Toolbar>
              <Typography variant="h6" color="inherit" noWrap>
                Season Parking Extension
              </Typography>
            </Toolbar> */}
          </AppBar>
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <Typography component="h1" variant="h4" align="center">
                Season Parking Renewal
              </Typography>
              <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
                {STEPS.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <React.Fragment>
                {this.state.activeStep === STEPS.length ? (
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                      Thank you for your application.
                    </Typography>
                    <Typography variant="subtitle1">
                    Please scan the following QR code and make payment  <u style={{'text-decoration': 'underline','font-weight':'bold'}}>within 15 minutes.</u><br/><br/>
                    If you are using <u style={{'font-weight':'bold'}}>PayNow Corporate</u>, ensure the authoriser approves the payment within <u style={{'text-decoration': 'underline','font-weight':'bold'}}>5 calendar days.</u><br /><br/>
                    Once the payment has been received, you will receive an acknowledgement email.
                    </Typography>
                    <br/>
                    <img src={QRCode} alt="image"></img>
                    <br/>
                    <Typography variant="subtitle1">
                      For any query, please contact it.support@allgreen.com.sg.
                    </Typography>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {this.getStepContent(this.state.activeStep, this.callbackDetail, this.state.fee)}

                    <div className={classes.buttons}>

                      {this.state.activeStep !== 0 && (
                        <Button onClick={this.handleBack} className={classes.button} disabled={this.state.disabled}>
                          Back
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        align="right"
                        disabled={this.state.disabled}
                        onClick={this.handleNext}
                        className={classes.button}
                      >
                        {this.state.activeStep === STEPS.length - 1 ? 'Place order' : 'Next'}
                      </Button>

                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            </Paper>
          </main>
          <Copyright />
          <HideFooter />
        </React.Fragment>
      )
  }
}

{/* <Route component={SeasonParkingExtension} />; */ }

export default withRouter(withStyles(styles)(SeasonParkingExtension))
