import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Select from 'react-select';
import { FormText } from "react-bootstrap";
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const columns = [
  { field: 'iu', headerName: 'IU', width:150, editable: false },
  { field: 'plate', headerName: 'Plate', width:150, editable: false }
]

const months = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 }
];

export class ApplicationReview extends Component {
  constructor(pros) {
    super(pros);
    this.state = {
      fee: 0
    };
  }

  render(){
    let startDate = moment(this.props.startDate).format('YYYY-MM-DD');
    let endDate = moment(this.props.endDate).format('YYYY-MM-DD');
    let name = this.props.name;
    let email = this.props.email;
    let companyName = this.props.companyName;
    let phone = this.props.phone
    let applicationMonth = this.props.applicationMonth;
    let applicationType = this.props.applicationType;
    let remark = this.props.remark;
    let units = this.props.units;
    let carCount = this.props.carCount;
    let rows = this.props.records;

    // let nameLength = this.props.name.length;
    // let encryptName = this.props.name.substr(0, 2) + "******" + this.props.name.substr(nameLength - 2, nameLength);
    
    // let phoneLength = this.props.phone.length;
    // let encryptPhone = this.props.phone.substr(0, 2) + "****" + this.props.phone.substr(phoneLength-2, phoneLength);

    // let emailSplit = this.props.email.split("@");
    // const encryptEmail = emailSplit[0].substr(0, 2) + "******@******" + emailSplit[1].substr(emailSplit[1].length-2, emailSplit[1].length);

    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              disabled="true"
              id="outlined-required"
              label="Building"
              defaultValue=""
              value={this.props.building}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Type</FormLabel>
              <RadioGroup
                disabled
                row
                aria-label="gender"
                name="controlled-radio-buttons-group"
                value={this.props.applicationType}
                onChange={(event) => {
                  this.setState({ applicationType: event.target.value })
                }}
              >
                {this.props.building!=="GWC" &&
                <FormControlLabel value="Public" control={<Radio />} label="Public" />
                }
                <FormControlLabel value="Tenant" control={<Radio />} label="Tenant" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {applicationType == "Tenant" &&
            <Grid item xs={12}>
              <TextField
                disabled
                id="units"
                name="units"
                label="Units"
                fullWidth
                autoComplete="shipping address-line2"
                value={units}
              />
            </Grid>}
            <Grid item xs={12}>
            {/* {
              errors.plateAndIU && <FormText style={{ color: 'red', fontWeight: 'bold' }}>{errors.plateAndIU}</FormText>
            }
            <br /> */}
            {/* <FormGroup>
              <FormControlLabel control={<Checkbox onChange={this.handleCheckChange} checked={checked} />} label="Add Second Car?" />
            </FormGroup> */}
            {/* <div style={{ height: '10%', width: '100%' }}>
              <DataGrid rows={rows} columns={columns} hideFooter='true' autoHeight='true' ref={x=>x} onCellEditCommit={this.handleCellEditCommit}  />
            </div> */}
             <TextField
              id="iu"
              name="iu"
              label="IU"
              fullWidth
              autoComplete="shipping address-line2"
              value={rows[0].iu} 
              disabled
            />
          </Grid>
          <Grid item xs={12}>
             <TextField
              id="plate"
              name="plate"
              label="Car Plate No."
              fullWidth
              autoComplete="shipping address-line2"
              value={rows[0].plate} 
              disabled
            />
          </Grid> 
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled
                label="Start Month*"
                views={['year', 'month']}
                value={startDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <FormText disabled>No. of Month(s)*</FormText>
            <Select
              isDisabled
              id="applicationMonth"
              name="applicationMonth"
              label="No. of Month(s) to Apply"
              fullWidth
              value={applicationMonth}
            />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled
                label="End Month*"
                value={endDate}
                views={['year', 'month']}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="name"
              name="name"
              label="Name*"
              fullWidth
              autoComplete="shipping address-line2"
              value={name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="email"
              name="email"
              label="Email*"
              fullWidth
              autoComplete="shipping address-line2"
              value={email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="phone"
              name="phone"
              label="Phone*"
              fullWidth
              autoComplete="shipping address-line2"
              value={phone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="companyName"
              name="companyName"
              label="Company Name*"
              fullWidth
              autoComplete="shipping address-line2"
              value={companyName}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              disabled
              id="remark"
              name="remark"
              label="Remark"
              fullWidth
              autoComplete="shipping address-line2"
              value={remark}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  
}
