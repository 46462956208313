import logo from './logo.svg';
import './App.css';

import SeasonParkingApplication from './Container/SeasonParkingApplication/SeasonParkingApplication';
import SeasonParkingSecondApplication from './Container/SeasonParkingSecondApplication/SeasonParkingSecondApplication';
import SeasonParkingTermination from './Container/SeasonParkingTermination/SeasonParkingTermination';
import SeasonParkingExtension from './Container/SeasonParkingExtension/SeasonParkingExtension';
import SeasonParkingUpdate from './Container/SeasonParkingUpdate/SeasonParkingUpdate';
import SeasonParkingPay from './Container/Pay/Home';
import LoginForm from './LoginForm';
import RecaptchaLoader from './Container/RecaptchaLoader/RecaptchaLoader';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import logo_gw from './img/logo_gw.png';
import logo_tanglin from './img/logo_tanglin.png';
import logo_seasonparking from './img/SeasonParking.svg'
import logo_prm from './img/logo_prm.png'

function App() {
  return (
    <BrowserRouter>
      <div class="header">
        <div class="brand">
          <img src={logo_seasonparking} />
        </div>
        <div class="logo">
          <div class="title">Participating Malls:</div>
          <img src={logo_gw} alt="Great World" />
          <img src={logo_tanglin} alt="Tanglin Mall" />
          <img src={logo_prm} alt="Pasir Ris Mall" />
        </div>
      </div>
      <div>
        <Switch className='HeaderBar'>
          <Route path="/Container/SeasonParkingApplication/SeasonParkingApplication"><SeasonParkingApplication/></Route>
          <Route path="/Container/SeasonParkingSecondApplication/SeasonParkingSecondApplication"><SeasonParkingSecondApplication/></Route>
          <Route path="/Container/SeasonParkingExtension/SeasonParkingExtension"><SeasonParkingExtension /></Route>
          <Route path="/Container/SeasonParkingTermination/SeasonParkingTermination"><SeasonParkingTermination/></Route>
          <Route path="/Container/SeasonParkingUpdate/SeasonParkingUpdate"><SeasonParkingUpdate/></Route>
          <Route path="/Container/pay/home"><SeasonParkingPay/></Route>
          {/* <Route path="/Update"><Update/></Route> */}
          <Route path="/"><LoginForm /></Route>
        </Switch>
      </div>
      <RecaptchaLoader/>
      {/* <div className='bottom'>
      </div> */}

    </BrowserRouter>
  );
}

export default App;