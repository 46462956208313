import { useEffect } from 'react';

function RecaptchaLoader() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    });

    return null; // This component doesn't render anything
}

export default RecaptchaLoader;
