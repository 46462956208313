import React, {Component} from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {withRouter } from 'react-router-dom';
import { Navigation } from '../../Navigation';
import img from "../../img/Success.jpg";
import { COPYRIGHT_BUSINESSENTITIES } from "../../Constants";
function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://shop.greatworld.com.sg/">
        {new Date().getFullYear()} {COPYRIGHT_BUSINESSENTITIES}
        </Link>{' '}
        
        {'.'}
      </Typography>
    );
  }
  const styles = theme => ({
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      align: 'right'
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  });
export class SeasonParkingPay extends Component{
    render(){
        const { classes } = this.props;
        return (
            <React.Fragment>
              <Navigation />
              <CssBaseline />
              <AppBar position="absolute" color="default" className={classes.appBar}>
              </AppBar>
               <main className={classes.layout}>
               <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
            Payment success
            </Typography>
            <React.Fragment>
            <Typography component="h1" variant="h4" align="center">
            <img  width="200" height="200" ALT="PAY" src={img}></img>
            </Typography>
                  <Typography component="h1" variant="h4" align="center">
                    Thank you for your Payment.
                  </Typography>
                  <br/>
                  <Typography variant="subtitle1">
                  For further query, please contact it.support@allgreen.com.sg. 
                  </Typography>
                 </React.Fragment>
          </Paper>
          <Copyright />
              </main>
            </React.Fragment>
          )
    }
} 
export default withRouter(withStyles(styles)(SeasonParkingPay))